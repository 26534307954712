import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux'
import Timestamp from 'react-timestamp'
// import { notificationAction } from './logic'
import { Loader, NoDataFound } from '../../components'
import { getRedirectUrl } from '../../utils'
import { drawerAction } from '../Drawer/logic'


const link = {
  buy_request: {
    link: 'marketplace/#/home/mysales/',
    action: 'review request'
  },
  author_action_buy_request: {
    link: 'marketplace/#/home/mytransactions/',
    action: 'view details'
  },
  transaction_complete_buyer: {
    link: 'marketplace/#/home/licensePurchased/',
    action: 'view license'
  },
  transaction_complete_author: {
    link: 'marketplace/#/home/licenseSold/',
    action: 'view details'
  }
}

class Notification extends Component {
  componentDidMount() {
    // this.props.notificationAction()
  }
  closeDrawer = () => {
    this.props.drawerAction({
      open: false,
      type: ''
    })
  }
  renderList() {
    const { intl } = this.props
    if (this.props.notification.data.length === 0) {
      return <NoDataFound style={{ height: 'calc(100vh - 120px)' }} message={intl.formatMessage({ id: 'No notification available', defaultMessage: 'No notification available' })} />
    }
    return this.props.notification.data.map((item, i) => (
      <div key={i} className='item' >
        <div className='has-space-btwn vcenter' >
          <div className='asset' >{item.title}</div>
          <div className={!item.is_read ? 'date unread' : 'date'} ><Timestamp time={new Date(item.date).getTime() / 1000} precision={1} /></div>
        </div>
        <div className='title' >{item.message}</div>
        <a href={`${getRedirectUrl('app', {}, {}, false)}${link[item.notification_type].link}`} className='field'>{link[item.notification_type].action}</a>
      </div>
    ))
  }
  render() {
    const style = {
      fontSize: 26,
      cursor: 'pointer',
      fontWeight: 'bold',
      transform: 'translateY(-25%)'
    }
    return (
      <div className='drawer-notification' >
        <div className='drawer-notification-heading'>
          <div className='title' ><FormattedMessage id="Notifications" defaultMessage="Notifications" /></div>
          <div role='presentation' onClick={this.closeDrawer} style={style} >×</div>
        </div>
        <Loader height='calc(100vh - 120px)' loading={this.props.notification.loading} error={this.props.notification.error} >
          <div className='drawer-notification-body scrollbar' >
            <div className='notification-list' >
              {this.renderList()}
            </div>
          </div>
        </Loader>
      </div>
    )
  }
}

Notification.propTypes = {
  // notificationAction: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  drawerAction: PropTypes.func.isRequired
}
Notification.defaultProps = {
}

const mapStateToProps = state => ({
  notification: state.notification,

})

export default connect(mapStateToProps, { drawerAction })(injectIntl(Notification))
