import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../../common/utils';
import { ERROR } from '../../../common/container/Status/logic';

export const STATUS_CANCEL = 'STATUS_CANCEL';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
};

// GETTING KOL CATEGORIES LOGIC START

export const KOLCATEGORIES_FETCH = 'KOLCATEGORIES_FETCH';
const KOLCATEGORIES_FETCH_SUCCESS = 'KOLCATEGORIES_FETCH_SUCCESS';
export const KOLCATEGORIES_FETCH_FAILURE = 'KOLCATEGORIES_FETCH_FAILURE';

export const KOLCATEGORIES_ACTIVE = 'KOLCATEGORIES_ACTIVE'

export const kolCategoriesActiveAction = payload => ({
  type: KOLCATEGORIES_ACTIVE,
  payload
});

export const kolCategoriesFetchAction = payload => ({
  type: KOLCATEGORIES_FETCH,
  payload
});

const kolCategoriesFetchSuccess = payload => ({
  type: KOLCATEGORIES_FETCH_SUCCESS,
  payload
});


// https://staging.facelift.iplexus.ai/api/v0/search/kolcount/
export const kolCategoriesFetchEpic = action$ => action$
  .ofType(KOLCATEGORIES_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/kolcount/`, 'GET', true, action.payload))
    .map(response => kolCategoriesFetchSuccess(response))
    .catch(error => Observable.of({
      type: KOLCATEGORIES_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })));


export function kolCategoriesFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case KOLCATEGORIES_FETCH: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case KOLCATEGORIES_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        flag: true
      }
    }
    case KOLCATEGORIES_FETCH_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false,
      }
    }
    default:
      return state
  }
}

export function kolCategoriesActiveReducer (state = 'thought_leaders', action = null) {
  switch (action.type) {
    case KOLCATEGORIES_ACTIVE: {
      return action.payload
    }
    default:
      return state
  }
}
