import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import COLOR from '../../container/palette.json'

class Pie extends Component {
  constructor(props) {
    super(props)
    this.outerRadius = 0
    this.innerRadius = 0
    this.arc = d3.arc()
    this.pie = d3.pie()
    this.svgID = (new Date()).getTime()
  }
  componentWillMount() {
    this.color = this.props.color
    this.outerRadius = Math.min(this.props.width / 2, this.props.height / 1.2) / 2
    this.innerRadius = this.props.type === 'donut' || this.props.type === 'donut_chart' ? this.outerRadius / 2 : 0
    this.arc
      .outerRadius(this.outerRadius)
      .innerRadius(this.innerRadius)
    this.pie
      .sort(null)
      .value(d => (
        +d.value
      ))
    this.total = 0
    this.props.data.forEach((d) => {
      this.total += d.value
    })
  }
  hightlight(index) {
    d3.selectAll(`.arc_${this.svgID}`)
      .style('opacity', 0.1)
    d3.select(`#Pie_${index}_${this.svgID}`)
      .style('opacity', 1)
      .on('click', () => {
        const obj = {}
        obj[this.props.xaxis] = this.props.data.map(d1 => d1.category)
        // obj[this.props.labelX] = this.props.data.map(d1 => d1.category).join()
        // obj[`Selected${this.props.labelX}`] = d.data.category
        // obj[`Selected${this.props.xaxis === 'Intervention' ? 'Drugs' : this.props.xaxis}`] = this.props.data[index].category
        obj.x_axis_filter = this.props.data[index].category
        obj.x_axis = this.props.xaxis
        obj.graph_type = this.props.type === 'donut' || this.props.type === 'donut_chart' ? 'donut_chart' : 'pie_chart'
        this.props.redirect(obj)
      })
    d3.select(`#Pie_center_text_${this.svgID}`)
      .append('tspan')
      .text(this.props.data[index].category)
    d3.select(`#Pie_center_text_${this.svgID}`)
      .append('tspan')
      .attr('y', '20')
      .attr('x', '2')
      .text(`${this.props.data[index].value} (${((this.props.data[index].value / this.total) * 100).toFixed(2)} %)`)
  }
  renderPie() {
    return this.pie(this.props.data).map((d, i) => {
      const id = `Pie_${i}_${this.svgID}`
      const midAngle = d.endAngle < Math.PI ? ((d.startAngle / 2) + (d.endAngle / 2)) : ((d.startAngle / 2) + (d.endAngle / 2) + Math.PI)
      return (
        <g
          id={id}
          key={`${i}_${this.svgID}`}
          className={`arc_${this.svgID} handicon`}
          style={{ opacity: 1 }}
          onMouseOver={(event) => {
            if (this.props.type === 'pie') {
              const svg = document.getElementById(`graph-${this.props.id}`)
              const p = svg.createSVGPoint()
              p.x = event.clientX
              p.y = event.clientY
              const svgp = p.matrixTransform(svg.getScreenCTM().inverse())
              const node = d3.select(`#graph-tooltip-id-${this.props.id}`).node()
              const content = `<div className='graph-tooltip-content'>
                    <div className='disp-flex header'>${d.data.category}</div>
                    <div className='sub-content'><span>Count: </span> ${d.data.value}</div>
                  </div>
                  `
              d3.select(`.graph-tooltip-${this.props.id}`)
                .html(content)
              d3.select(`.graph-tooltip-${this.props.id}`)
                .transition()
                .duration(200)
                .style('visibility', 'visible')
                .style('left', `${svgp.x - (node.offsetWidth / 2)}px`)
                .style('top', `${svgp.y - (node.offsetHeight * 1.2)}px`)
            } else {
              this.hightlight(i)
            }
          }}
          onFocus={() => console.log()}
          onBlur={() => console.log()}
          onMouseOut={() => {
            if (this.props.type === 'pie') {
              d3.select(`.graph-tooltip-${this.props.id}`)
                .transition()
                .duration(200)
                .style('visibility', 'hidden')
            } else {
              d3.selectAll(`.arc_${this.svgID}`)
                .style('opacity', 1)
              d3.select(`#Pie_center_text_${this.svgID}`)
                .text('')
            }
          }}
        >
          <path
            id={`${id}_arc`}
            d={this.arc(d)}
            stroke={this.props.color[d.data.category] || this.props.color[i]}
            fill={this.props.color[d.data.category] || this.props.color[i]}
          />
          <text
            transform={`translate(${this.arc.centroid(d)[0]},${this.arc.centroid(d)[1]}) rotate(-90) rotate(${(midAngle * 180) / Math.PI})`}
            textAnchor='middle'
            dy='0.3em'
          >
            {this.props.type === 'donut' || this.props.type === 'donut_chart' ? '' : d.data.category}
          </text>
        </g>
      )
    })
  }
  renderText() {
    return (
      <text
        textAnchor='middle'
        id={`Pie_center_text_${this.svgID}`}
      />
    )
  }
  render() {
    return (
      <g transform={`translate(${this.props.width / 2}, ${this.props.height / 2})`}>
        {this.renderPie()}
        {this.renderText()}
      </g>
    )
  }
}

Pie.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  redirect: PropTypes.func,
  xaxis: PropTypes.string
}
Pie.defaultProps = {
  type: 'pie',
  id: '1',
  xaxis: 'Intervention',
  redirect: () => null,
  color: COLOR
}

export default Pie
