import Rcookie from 'react-cookies'
import { parse, stringify } from 'query-string'
import cmp from 'semver-compare'

export const PATHS = [...Object.keys(process.env.APPS).map(item => process.env.APPS[item].mainRoute), '/academic']

export const setCookie = (value, key) => {
  const { domain } = process.env
  const expires = new Date()
  expires.setDate(new Date().getDate() + 1)
  Rcookie.save(`${process.env.app}_${key}_${process.env.type}`, value, {
    path: '/', domain, expires, ...(process.env.type === 'development' ? {} : { secure: true })
  })
  // document.cookie = `${process.env.app}_${key}_${process.env.type}=${value}; expires=${expires};`
}

export const removeCookies = () => {
  const cookies = document.cookie.split(';')
  const { domain } = process.env
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    PATHS.map((item) => {
      document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
      return 0
    })
  }
}

export const parseHashUrl = value => parse(value.location.hash.replace('#', '?'))

export const generateHashUrl = value => `#${stringify(value)}`

export const j = value => JSON.parse(JSON.stringify(value))

export const isJsonString = (str, backup = []) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return backup
  }
}

export const formQuery = value => value.map(item => `${item.operator ? `${item.operator} ` : ''}${item.class === 'author' ? `${item.value}${item.id ? `::${item.id}` : ''}` : item.value}`).join(' ')
export const formDisplayQuery = value => value.map(item => `${item.operator ? `${item.operator} ` : ''}${item.value || item.preferred_name || ''}`).join(' ')

export const formSearchTreeQuery = (value) => {
  const data = j(value).filter(item => !item.disabled)
  if (data.length) {
    data[0].operator = ''
  }
  return data.map(({ count, disabled, ...others }) => others)
}

export const uniqueFromArray = value => value.filter((item, pos) => value.indexOf(item) === pos)

export const useKeyOnly = (val, key) => val && key

export const useKeyOrValueAndKey = (val, key) => val && (val === true ? key : `${val} ${key}`)

export const useValueAndKey = (val, key) => val && val !== true && `${val} ${key}`

export const useTextAlignProp = val => (val === 'justified' ? 'justified' : useValueAndKey(val, 'aligned'))

export const getCookie = (key, app = process.env.app) => Rcookie.load(`${app}_${key}_${process.env.type}`)

export const checkPermission = (value) => {
  const chunkLength = getCookie('pChunks')
  if (chunkLength !== undefined) {
    return Array.from({ length: parseInt(chunkLength, 10) }).some((item, i) => getCookie(`permissions-${i}`).indexOf(value) > -1)
  }
  // if (getCookie('permissions')) {
  //   return getCookie('permissions').indexOf(value) > -1
  // }
  return false
}

export const getAllPermissions = () => {
  const chunkLength = getCookie('pChunks')
  let result = []
  if (chunkLength !== undefined) {
    Array.from({ length: parseInt(chunkLength, 10) }).forEach((item, i) => {
      result = [...result, ...getCookie(`permissions-${i}`)]
    })
  }
  return result
}

export const splitToChunks = (array, parts) => {
  const result = [];
  for (let i = parts; i > 0; i -= 1) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

export function apiCall(url, method, authReq = true, body = {}) {
  let obj = {}
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body
    }
  } else {
    obj = {
      method,
      url
    }
  }
  if (authReq) {
    return ({
      ...obj,
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
  }
  return ({
    ...obj,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  })
}

export function apiCallFetch(method, authReq = true, body = {}) {
  let obj = {}
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      body: JSON.stringify(body),
      credentials: 'same-origin',
    }
  } else {
    obj = {
      method,
    }
  }
  if (authReq) {
    return ({
      ...obj,
      headers: {
        Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
  }
  return ({
    ...obj,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  })
}

export function bookmarkStatusToggle(data = [], id = null, flag = false) {
  const DATA = JSON.parse(JSON.stringify(data))
  return DATA.map(item => (item.doc_id === id ? { ...item, bookmark: flag } : item))
}

export function bibliographyStatusToggle(data = [], id = null, flag = false) {
  const DATA = JSON.parse(JSON.stringify(data))
  return DATA.map(item => (item.doc_id === id ? { ...item, bibliography: flag } : item))
}

export function annotationStatusToggle(data = [], id = null, flag = false) {
  const DATA = JSON.parse(JSON.stringify(data))
  return DATA.map(item => (item.doc_id === id ? { ...item, annotation: flag } : item))
}

export function innoPattern(name, app) {
  function actionType(type) {
    if (app) {
      return `${app}_${name}_${type}`
    }
    return `${name}_${type}`
  }

  function createReducer(cases, defaultState = {}) {
    return function reducer(state = defaultState, action = {}) {
      if (state === undefined) {
        return defaultState
      }
      Object.keys(cases).forEach((caseName) => {
        if (action.type === caseName) {
          return cases[caseName](state, action)
        } return null
      })
      return state
    }
  }

  function createAction(type) {
    return function actionCreator(payload) {
      const action = {
        type
      }

      if (payload) {
        action.payload = payload
      }
      return action
    }
  }

  return {
    actionType,
    createReducer,
    createAction
  }
}


export function getRedirectUrl(app, params = {}, backParams = {}, backUrlRequired = true) {
  const search = parse(window.location.search)
  const hashSearch = parseHashUrl(window)
  const appLabel = (search.appLabel || hashSearch.appLabel || process.env.appLabel) ? { appLabel: search.appLabel || hashSearch.appLabel || process.env.appLabel } : {}
  if (!backUrlRequired && params && Object.keys(params).length) {
    return `${process.env.APPS[app].redirectUrl}?${stringify({ ...params, ...appLabel })}#${stringify({ ...params, ...appLabel })}`
  } else if (!backUrlRequired) {
    return process.env.APPS[app].redirectUrl
  }
  if (app !== process.env.current) {
    const backUrl1 = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search), ...backParams })}#${stringify({ ...parseHashUrl(window), ...backParams })}`
    return `${process.env.APPS[app].redirectUrl}?${stringify({
      backUrl: backUrl1, backApp: process.env.backLabel, ...(appLabel), ...params
    })}`
  }
  const { backUrl } = parse(window.location.search)
  return `${process.env.APPS[app].redirectUrl}?${stringify({ ...(appLabel), ...params, backUrl })}`
}

export function marshalAuthor(authorName, authorId) {
  let author = authorName.trim().replace(/ /g, '~')
  author = author.charAt(0) === '@' ? author : `@${author}`
  author = authorId ? `${author}::${authorId}` : author
  return author
}

export function unmarshalAuthor(authorStr) {
  return authorStr.trim().split('::')[0].replace('@', '').replace(/~/g, ' ');
}

export function authorFunc(iStr, isRemove, isModify) {
  const dictionary = JSON.parse(localStorage.getItem('dictionary'))
  if (iStr) {
    const _wordArray = iStr.replace(/\s\s+/g, ' ').split(' ')
    for (let i = 0; i < _wordArray.length; i += 1) {
      if (_wordArray[i][0] === '@') {
        if (isRemove) {
          _wordArray[i] = _wordArray[i].indexOf('::') !== -1 ? _wordArray[i].substr(0, _wordArray[i].indexOf('::')) : _wordArray[i]
          _wordArray[i] = _wordArray[i].replace(/~/g, '-')
        } else if (dictionary && dictionary[_wordArray[i]]) {
          if (dictionary[_wordArray[i]].author_id) {
            _wordArray[i] = `${dictionary[_wordArray[i]]['mod-name']}::${dictionary[_wordArray[i]].author_id}`
          } else {
            _wordArray[i] = dictionary[_wordArray[i]]['mod-name']
          }
        }
      } else if (isModify) {
        _wordArray[i] = _wordArray[i].replace(/-/g, ' ').replace(/ {2}/g, '-')
      }
    }
    return _wordArray.join(' ')
  }
  return ''
}

export function queryConcator(iStr, iId) {
  return `${iStr}::${iId}`
}

export function spaceReplacer(iStr, iReplacer) {
  return iStr.replace(/\s+/g, iReplacer)
}

export function convertObject(iKey, iObj) {
  let _tempObj = {}
  const _iObj = iObj
  if (localStorage.getItem('dictionary')) {
    _tempObj = JSON.parse(localStorage.getItem('dictionary'))
  } else {
    _tempObj = {}
  }
  const _tempNameForRender = spaceReplacer(iKey, '-')
  _iObj['mod-name'] = spaceReplacer(iKey, '~')
  _tempObj[_tempNameForRender] = _iObj
  localStorage.setItem('dictionary', JSON.stringify(_tempObj))
  return _tempObj
}

export const style = {
  style: {
    background: 'rgba(62, 62, 62, 0.9)',
    padding: 20,
    color: '#fff',
    borderRadius: 5,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 2px',
    'z-index': '200',
    // marginTop: -60
  },
  arrowStyle: {
    color: 'rgba(62, 62, 62, 0.9)',
    borderColor: false,
    transition: 'none'
  }
}

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateTabsList(tabs, tabsMapping) {
  return tabs.map((tab) => {
    const matchingTab = tabsMapping.find(x => x.value === tab.value);

    if (matchingTab) {
      return { component: matchingTab.component, premium: matchingTab.premium, ...tab };
    }
    return null;
  }).filter(Boolean)
}

export function getSearchQueryFromCrumbs(crumbs) {
  return crumbs
    .filter(crumb => !crumb.disabled)
    .map((crumb, i) => (i === 0 ? crumb.value : `${crumb.filter} ${crumb.value}`))
    .join(' ')
}

export function getCategoryHookParams(hook) {
  const params = {
    Gene: {
      doc: hook.doc_id,
      q: hook.term
    },
    Drug: {
      doc: hook.doc_id,
      q: hook.term,
      drugtype: hook.type
    },
    Preclinical: {
      doc: hook.doc_id,
      q: hook.term
    },
    Author: {
      q: `@${hook.term.replace(/ /g, '~')}::${hook.doc_id}`
    }
  }
  return params[hook.title]
}

export function getAppHookMapping(hook) {
  const apps = {
    Gene: 'gene',
    Drug: 'intervention',
    Preclinical: 'preclinical',
    Author: 'author'
  }
  return apps[hook.title]
}

export function getAssetClassMapping(asset, isSingular) {
  const assets = {
    publications: { singular: 'Publication', plural: 'Publications' },
    clinicaltrials: { singular: 'Clinical Trial', plural: 'Clinical Trials' },
    grants: { singular: 'Grant', plural: 'Grants' },
    recent_events: { singular: 'News & Press Release', plural: 'News & Press Releases' },
    guidelines: { singular: 'Treatment Guidelines', plural: 'Treatment Guidelines' },
    socialmedia: { singular: 'Social Media', plural: 'Social Media' },
    patent: { singular: 'Patent', plural: 'Patents' },
    thesis: { singular: 'Theses & Dissertations', plural: 'Theses & Dissertations' },
    congresses: { singular: 'Congress', plural: 'Congresses' },
    regulatory_updates: { singular: 'Regulatory Update', plural: 'Regulatory Updates' },
    deals: { singular: 'Deal', plural: 'Deals' },
  }
  return (assets[asset] && (isSingular ? assets[asset].singular : assets[asset].plural)) || asset;
}

export function sortObject(oldObj) {
  const tempObj = JSON.parse(JSON.stringify(oldObj))
  const newObj = {}
  Object.keys(tempObj).sort().forEach((key) => { newObj[key] = tempObj[key] })
  return newObj
}

export function sortArrayObject(oldObj, key) {
  const tempObj = JSON.parse(JSON.stringify(oldObj))
  tempObj.sort((a, b) => {
    const nameA = a[key].toLowerCase()
    const nameB = b[key].toLowerCase()
    if (nameA < nameB) {
      return -1
    } else if (nameA > nameB) {
      return 1
    }
    return 0
  })
  return tempObj
}

export function generateStringArray(array, key) {
  const a = []
  for (let i = 0; i < array.length; i += 1) {
    a.push(array[i][key]);
  }
  return a
}

export const graphColor = ['#30679B', '#CA9546', '#149F84', '#9B9D1C', '#50D5E3', '#7483C9', '#F4D341', '#55B7ED', '#7B63B3', '#C8CFF0', '#30679B', '#CA9546', '#149F84', '#9B9D1C', '#50D5E3', '#7483C9', '#F4D341', '#55B7ED', '#7B63B3', '#C8CFF0']
export const borderColor = ['#30679B', '#CA9546', '#149F84', '#9B9D1C', '#50D5E3', '#7483C9', '#F4D341', '#55B7ED', '#7B63B3', '#C8CFF0', '#30679B', '#CA9546', '#149F84', '#9B9D1C', '#50D5E3', '#7483C9', '#F4D341', '#55B7ED', '#7B63B3', '#C8CFF0']

export const setAnalytics = (trigger, key, data) => {
  // analytics.track(trigger, { label: JSON.stringify({ [key]: data }) });
  if (dataLayer) {
    dataLayer.push({
      event: trigger,
      ...(key ? { [key]: data } : {})
    })
  }
}

export const scrollToTarget = (container, target) => {
  const myTarget = document.querySelector(target);
  const parent = document.querySelector(container)
  if (myTarget && parent) {
    parent.scroll({
      top: myTarget.offsetTop - parent.offsetTop,
      behavior: 'smooth'
    });
  }
}
export function stringComparator(a, b, reverse = false) {
  const pos = a.toLowerCase().localeCompare(b.toLowerCase())
  return reverse ? -1 * pos : pos
}

export const equalizerQuery = value => Object.keys(value).map(item => `${item}:${value[item]}`).join(',')

export const readableDate = (date) => {
  const newDate = (new Date(date)).getTime() > 0 ? new Date(date) : new Date(parseInt(date, 10));
  // console.log(date, (new Date(date)).getTime() > 0, new Date(date), new Date(parseInt(date, 10)))
  return Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(newDate)
}

export const guidGenerator = () => {
  const S4 = () => Math.random().toString(36).substring(7)
  return (`${S4()}${S4()}_${S4()}_${S4()}`)
}

export const timeStampUTC = (timeStamp) => {
  const localOffset = (-1) * new Date(timeStamp * 1000).getTimezoneOffset() * 60000;
  const stamp = Math.round(new Date((timeStamp * 1000) + localOffset).getTime() / 1000);
  return stamp
}


export function getCategoryHookParamsV1(hook) {
  const params = {
    Gene: {
      doc: hook.hook_id,
      q: hook.term,
      type: hook.type,
      class: hook.class,
      id: hook.id
    },
    Drug: {
      doc: hook.hook_id,
      q: hook.term,
      drugtype: hook.title,
      type: hook.type,
      class: hook.class,
      id: hook.id
    },
    Preclinical: {
      doc: hook.hook_id,
      q: hook.term,
      type: hook.type,
      class: hook.class,
      id: hook.id
    },
    Author: {
      doc: hook.hook_id,
      q: `@${hook.term.replace(/ /g, '~')}::${hook.hook_id}`,
      type: hook.type,
      class: hook.class,
      id: hook.id
    }
  }
  return params[hook.entity_type]
}
export function getAppHookMappingV1(hook) {
  const apps = {
    Gene: 'gene',
    Drug: 'intervention',
    Preclinical: 'preclinical',
    Author: 'author'
  }
  return apps[hook.entity_type]
}

export function generateRandomString(stringLength = 10) {
  let randomString = '';
  let randomAscii;
  const asciiLow = 65;
  const asciiHigh = 90
  for (let i = 0; i < stringLength; i += 1) {
    randomAscii = Math.floor((Math.random() * (asciiHigh - asciiLow)) + asciiLow);
    randomString += String.fromCharCode(randomAscii)
  }
  return randomString
}

export const languageOptions = [
  'english',
  'german',
  'french'
]

export const filterArrayJoin = (value) => {
  const a = j(isJsonString(value, {}))
  Object.keys(a).forEach((item) => {
    if (Array.isArray(a[item])) {
      a[item] = a[item].join(',')
    }
  })
  return a
}


const minBrowserVersions = {
  chrome: '64',
}

export const checkBrowserSupport = browser => !!minBrowserVersions[browser.name] &&
  (cmp(browser.version, minBrowserVersions[browser.name]) > 0)
