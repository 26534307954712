import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { ERROR } from '../Status/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}
const FULL_ABSTRACT = 'FULL_ABSTRACT'
export const FULL_ABSTRACT_SUCCESS = 'FULL_ABSTRACT_SUCCESS'
const FULL_ABSTRACT_FAILURE = 'FULL_ABSTRACT_FAILURE'


// Fetch Full Abstract action
export const fullAbstractAction = payload => ({
  type: FULL_ABSTRACT,
  payload
})

// Fetch Full Abstract Success action
export const fullAbstractSuccess = payload => ({
  type: FULL_ABSTRACT_SUCCESS,
  payload
})

// Fetch Full Abstract epic
export const fullAbstractEpic = action$ => action$
  .ofType(FULL_ABSTRACT)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/details/allFieldsData/?${action.payload}`, 'GET'))
    .map(response => fullAbstractSuccess(response))
    .catch(error => Observable.of({
      type: FULL_ABSTRACT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// fetch Full Abstract Reducer
export function fullAbstractReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case FULL_ABSTRACT: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case FULL_ABSTRACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case FULL_ABSTRACT_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
