import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';

const PublicationWiley = (props) => {
  const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <Fragment>
      {props.data.Abstract
      ? (
        <div className='card-body-content'>
          <div className='sub-title'><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
          <div id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div>
        </div>
      ) : null}
      <div className='card-body-content disp-flex' >
        {props.data['Publication ID'] ? <Fragment><div className='pmid'><div className='sub-title'><FormattedMessage id="PMID" defaultMessage="PMID" /></div><div className='sub-text'> {props.data['Publication ID']} </div></div> </Fragment> : null}
        {props.data.DOI ? <Fragment><div className='doi'><div className='sub-title'><FormattedMessage id="DOI" defaultMessage="DOI" /></div><div className='sub-text'> { props.data.DOI } </div> </div> </Fragment> : null}
      </div>
    </Fragment>
  )
}

PublicationWiley.propTypes = {
  data: PropTypes.object.isRequired
}

export default PublicationWiley
