import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ClickableAuthors, ListIcons, AssetTag } from '../'
import { generateStringArray } from '../../utils'
import ShowMore from '../ShowMore'

const Grant = (props) => {
  const renderTags = () => {
    let tags = []
    if (props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name')
    }
    return tags.length && (<ShowMore data={[{ value: tags }]} />)
  }
  const getPrincipalInvestigator = () => ({
    Authors: props.data['Principal Investigator']
  })
  return (
    <Fragment>
      {props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
        </div>
      }
      <div className='card-details'>
        <div className='card-meta'>

          <AssetTag asset={props.data.type} />
          {props.data.Institution && <div className='card-meta-title text-elips'>{props.data.Institution}</div>}
          {props.data.Date && <div className='date'>{props.data.Date}</div>}
        </div>
        <h3 className='card-title'>
          {props.data['Grant ID'] && <span className='grant-id'>{`${props.data['Grant ID']} | `}</span>}
          {ReactHtmlParser(props.data.Title)}
        </h3>
        <div className='card-text'>
          {props.data['Grant Type'] && (
            <div className='card-subtext'>
              <div className='title'><FormattedMessage id="Grant Type" defaultMessage="Grant Type" />:</div>
              <div className='value'>{props.data['Grant Type']}</div>
            </div>)}
          {props.data['Principal Investigator'] && (
            <div className='card-subtext'>
              <div className='title'><FormattedMessage id="Principal Investigator" defaultMessage="Principal Investigator" />:</div>
              {/* <div className='value'>{props.data['Principal Investigator']}</div> */}
              <div className='value'><ClickableAuthors data={getPrincipalInvestigator()} limit={3} /></div>
            </div>)}
          {props.data.Tags.length > 0 && props.showMore && (
            <div className='card-body-content' >
              <div className='sub-title'><FormattedMessage id="FINGERPRINTS" defaultMessage="FINGERPRINTS" />:</div>
              <div className='tag-list-wrap'>{renderTags()}</div>
            </div>)}
        </div>
      </div>
      {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
    </Fragment>
  )
}


Grant.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  showMore: PropTypes.bool,
  overlay: PropTypes.bool,
}

Grant.defaultProps = {
  flag: true,
  likeParams: {},
  takeFeedback: true,
  showMore: false,
  overlay: true
}
export default Grant
