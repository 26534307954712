import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../../common/utils';
import { ERROR } from '../../../common/container/Status/logic';

export const STATUS_CANCEL = 'STATUS_CANCEL';

const INITIAL_STATE = {
  results: [],
  loading: false,
  error: false,
  flag: false
};

export const KOLAUTHORESRESULTS_FETCH = 'KOLAUTHORESRESULTS_FETCH';
const KOLAUTHORESRESULTS_FETCH_SUCCESS = 'KOLAUTHORESRESULTS_FETCH_SUCCESS';
export const KOLAUTHORESRESULTS_FETCH_FAILURE = 'KOLAUTHORESRESULTS_FETCH_FAILURE';
export const KOLAUTHORESRESULTS_FETCH_MORE = 'KOLAUTHORESRESULTS_FETCH_MORE';
const KOLAUTHORESRESULTS_FETCH_MORE_SUCCESS = 'KOLAUTHORESRESULTS_FETCH_MORE_SUCCESS';


export const kolAuthorsResultsFetchAction = payload => ({
  type: KOLAUTHORESRESULTS_FETCH,
  payload
});

const kolAuthorsResultsFetchSuccess = payload => ({
  type: KOLAUTHORESRESULTS_FETCH_SUCCESS,
  payload
});

export const kolAuthorsResultsFetchMoreAction = payload => ({
  type: KOLAUTHORESRESULTS_FETCH_MORE,
  payload
});

const kolAuthorsResultsFetchMoreSuccess = payload => ({
  type: KOLAUTHORESRESULTS_FETCH_MORE_SUCCESS,
  payload
});

export const kolAuthorsResultsFetchEpic = action$ => action$
  .ofType(KOLAUTHORESRESULTS_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/kol/list?authorId=${action.payload}`, 'GET', true, action.payload))
    .map(response => kolAuthorsResultsFetchSuccess(response))
    .catch(error => Observable.of({
      type: KOLAUTHORESRESULTS_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })));

export const kolAuthorsResultsFetchMoreEpic = action$ => action$
  .ofType(KOLAUTHORESRESULTS_FETCH_MORE)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/kol/list?authorId=${action.payload}`, 'GET', true, action.payload))
    .map(response => kolAuthorsResultsFetchMoreSuccess(response))
    .catch(error => Observable.of({
      type: KOLAUTHORESRESULTS_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })));

export function kolResultsFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case KOLAUTHORESRESULTS_FETCH: {
      return {
        ...state,
        results: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case KOLAUTHORESRESULTS_FETCH_SUCCESS: {
      return {
        ...state,
        results: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case KOLAUTHORESRESULTS_FETCH_MORE_SUCCESS: {
      return {
        ...state,
        results: [...state.results, ...action.payload.response.data],
        loading: false,
        error: false,
        flag: true
      }
    }
    case KOLAUTHORESRESULTS_FETCH_FAILURE: {
      return {
        ...state,
        results: [],
        loading: false,
        error: true,
        flag: false,
      }
    }
    default:
      return state
  }
}
