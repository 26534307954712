import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { stringify, parse } from 'query-string'
import { Loader, AbstractType } from '../../components'
import Bookmark from '../Bookmark'
import Bibliography from '../Bibliography'
import Annotation from '../Annotation'
import { fetchAbstractAction } from './logic'
import { setAnalytics, checkPermission } from '../../utils'

class Abstract extends Component {
  constructor(props) {
    super(props)
    this.onBackClick = this.onBackClick.bind(this)
    this.apiCallAbstract = this.apiCallAbstract.bind(this)
    this.state = {
      bookmark: props.data.bookmark,
      bibliography: props.data.bibliography
    }
  }
  componentWillMount() {
    if (!this.props.fullAbstract) {
      document.body.style.overflow = 'hidden'
    }
    this.apiCallAbstract()
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.abstract.flag !== nextProps.abstract.flag && nextProps.abstract.flag) {
      if (this.props.scroll) {
        this.onScroll()
      }
    }
    if ((nextProps.bookmark.id === this.props.data.doc_id) && ((this.props.bookmark.id !== nextProps.bookmark.id && nextProps.bookmark.id) ||
      (this.props.bookmark.id === nextProps.bookmark.id && nextProps.bookmark.id && this.props.bookmark.status !== nextProps.bookmark.status))) {
      this.setState({
        bookmark: nextProps.bookmark.status
      })
    }
    if ((nextProps.bibliography.id === this.props.data.doc_id) && ((this.props.bibliography.id !== nextProps.bibliography.id && nextProps.bibliography.id) ||
      (this.props.bibliography.id === nextProps.bibliography.id && nextProps.bibliography.id && this.props.bibliography.status !== nextProps.bibliography.status))) {
      this.setState({
        bibliography: nextProps.bibliography.status
      })
    }
  }
  onScroll = () => {
    setTimeout(() => {
      const toId = document.getElementById('abstract-citation-list')
      const id = document.getElementById('abstract-modal-id-special')
      if (id && toId) {
        id.scrollTop = toId.offsetTop - 20
      }
    }, 100)
  }
  onBackClick() {
    this.props.onBack()
    document.body.style.overflow = 'auto'
  }
  apiCallAbstract() {
    const q = stringify({
      dataset: this.props.dataset || this.props.type,
      doc_id: this.props.data.doc_id
    })
    setAnalytics('abstractClickTrigger', 'abstractClick', JSON.stringify({
      dataset: this.props.type,
      doc_id: this.props.data.doc_id
    }))
    this.props.fetchAbstractAction(q)
  }
  render() {
    const { intl } = this.props
    const { data } = this.props.abstract
    const abstractCheck = !!data.Abstract || !!data.Cliams || !!data['Study Summary']
    return (
      <Fragment>
        <div className='card-modal-head container'>
          <div className='disp-flex vcenter' >
            {this.props.onBack && <a className='go-back' role='presentation' onClick={() => this.onBackClick()} ><i className='back-arrow-left' /> <FormattedMessage id="Back to list" defaultMessage="Back to list" /></a>}
          </div>
          <div className='disp-flex vcenter' >
            {!this.props.hide && (
              <Fragment>
                {!this.props.abstract.loading && abstractCheck && parse(this.props.location.search).group !== 'Unpublished' && checkPermission('myworkspace_permission') && <Annotation annotationDependent={false} group='Published' dataset={this.props.type} id={this.props.data.doc_id} />}
                {this.props.showBookmark && <Bookmark flag={this.state.bookmark} type={this.props.type} id={this.props.data.doc_id} group={parse(this.props.location.search).group || 'Published'} />}
                {checkPermission('my_bibliography_permission') && checkPermission(`${this.props.type}_bibliography_permission`) &&
                  <div className='margin-lg-left' >
                    <Bibliography flag={this.state.bibliography} type={this.props.type} id={this.props.data.doc_id} group={parse(this.props.location.search).group || 'Published'} />
                  </div>
                }
                {checkPermission('myworkspace_permission') && <div className='card-divider' />}
              </Fragment>
            )}
            {(this.props.data['Source Link'] || (this.props.abstract.data && this.props.abstract.data['Source Link'])) && (
              <a
                title={intl.formatMessage({ id: 'Source link', defaultMessage: 'Source link' })}
                onClick={(e) => {
                  setAnalytics('sourceLinkTrigger', 'sourceLink', (this.props.data['Source Link'] || this.props.abstract.data['Source Link']).match(/^https?:/) ? (this.props.data['Source Link'] || this.props.abstract.data['Source Link']) : `http://${(this.props.data['Source Link'] || this.props.abstract.data['Source Link'])}`);
                  e.stopPropagation()
                }}
                rel="noopener noreferrer"
                className='web-link'
                target='_blank'
                href={(this.props.data['Source Link'] || this.props.abstract.data['Source Link']).match(/^https?:/) ? (this.props.data['Source Link'] || this.props.abstract.data['Source Link']) : `http://${(this.props.data['Source Link'] || this.props.abstract.data['Source Link'])}`}
              />
            )}
          </div>
        </div>
        <Loader height={this.props.height} loading={this.props.abstract.loading} error={this.props.abstract.error} >
          <div id='abstract-modal-id-special' className='card-modal-body relative container scrollbar'>
            <AbstractType
              {...this.props}
              data={this.props.data}
              type={this.props.type}
              abstract={this.props.abstract}
              id={this.props.data.doc_id}
              openAccordion={this.props.scroll && this.props.type === 'publications'}
            />
          </div>
        </Loader>
      </Fragment>
    )
  }
}

Abstract.propTypes = {
  fetchAbstractAction: PropTypes.func.isRequired,
  abstract: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  location: PropTypes.object.isRequired,
  bookmark: PropTypes.object.isRequired,
  bibliography: PropTypes.object.isRequired,
  showBookmark: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  dataset: PropTypes.string,
  hide: PropTypes.bool,
  scroll: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  fullAbstract: PropTypes.bool,
}
Abstract.defaultProps = {
  onBack: null,
  showBookmark: true,
  height: 'calc(100vh - 100px)',
  dataset: null,
  hide: false,
  scroll: false,
  fullAbstract: false
}

const mapStateToProps = state => ({
  abstract: state.abstract,
  bookmark: state.bookmark,
  bibliography: state.bibliography
})

export default withRouter(connect(mapStateToProps, { fetchAbstractAction })(injectIntl(Abstract)))
