import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { PATHS } from '../../utils'

const Footer = (props) => {
  if (PATHS.indexOf(props.location.pathname) > -1 && props.location.pathname !== '/academic') {
    return null
  }
  const year = new Date().getFullYear()
  return (
    <footer
      style={{
      width: '100%',
      backgroundColor: '#fff'
    }}
      className='footer-head'
    >
      <div className='container footer-align' >
        <div className='logo-with-link'>
          <a href='https://www.innoplexus.com/' rel="noopener noreferrer" className='footer-innoplexus-logo' target='_blank' />
          <div className='footer-menus'>
            <a href={`${process.env.mainAppUrl}/contactus`} className='link-footer-menu'>
            Contact
            </a>
            <a href={`${process.env.mainAppUrl}/privacypolicy`} className='link-footer-menu'>
            Privacy Policy
            </a>
            <a href={`${process.env.mainAppUrl}/userpolicy`} className='link-footer-menu'>
            User Policy
            </a>
          </div>
        </div>
        <div className='copy'>
        Copyright © {year} -  <a rel="noopener noreferrer" target="_blank" className='link-footer-menu last-txt' href='https://www.innoplexus.com/'> Innoplexus<sup>&reg;</sup></a>
        </div>
      </div >
    </footer>
  )
}

Footer.propTypes = {
  location: PropTypes.object.isRequired
}


export default withRouter(Footer)
