import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkTokenAction } from './logic'
import { removeCookies, getCookie } from '../../utils';
import SpecialLoader from '../../components/SpecialLoader'

const CheckAccessToken = () => (BaseComponent) => {
  class CheckAccessTokenCheck extends Component {
    constructor (props) {
      super(props)
      this.state = {
        loading: true
      }
    }
    componentWillMount() {
      if (getCookie('accessToken')) {
        this.props.checkTokenAction()
      } else {
        this.setState({
          loading: false
        })
      }
    }
    componentWillReceiveProps (nextProps) {
      if (nextProps.checkToken.error !== this.props.checkToken.error && nextProps.checkToken.error) {
        const query = parse(window.location.hash).alreadyLoggedIn
        const hash = parse(window.location.search).alreadyLoggedIn
        removeCookies()
        if (!(query === 'true' || hash === 'true')) {
          window.location.replace(`${process.env.mainAppUrl}`)
        } else {
          this.setState({
            loading: false
          })
        }
      }
      if (nextProps.checkToken.flag !== this.props.checkToken.flag && nextProps.checkToken.flag) {
        this.setState({
          loading: false
        })
      }
    }
    render() {
      if (this.state.loading) {
        return (
          <SpecialLoader />
        )
      }
      return <BaseComponent {...this.props} />
    }
  }
  CheckAccessTokenCheck.propTypes = {
    checkTokenAction: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    checkToken: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }
  CheckAccessTokenCheck.defaultProps = {
  }
  const mapStateToProps = state => ({
    checkToken: state.checkToken
  })
  return withRouter(connect(mapStateToProps, { checkTokenAction })(CheckAccessTokenCheck))
}
export default CheckAccessToken
