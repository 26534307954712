export const sidOpenable = [
  'publications_w'
]

export const deepDives = [
  'publications',
  'clinicaltrials',
  'grants',
  'guidelines',
  'socialmedia',
  'patent',
  'thesis',
  'congresses',
  'Pre Clinical',
  'Drug Discovery',
  'Clinical',
  'publications_w'
]
