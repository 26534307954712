import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
const REQUEST_DEMO = 'REQUEST_DEMO'
export const REQUEST_DEMO_SUCCESS = 'REQUEST_DEMO_SUCCESS'
const REQUEST_DEMO_FAILURE = 'REQUEST_DEMO_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const requestDemoAction = payload => ({
  type: REQUEST_DEMO,
  payload
})

const requestDemoSuccess = payload => ({
  type: REQUEST_DEMO_SUCCESS,
  payload
})

export const requestDemoEpic = action$ => action$
  .ofType(REQUEST_DEMO)
  .mergeMap(action => staticAjax(apiCall(`${process.env.mainAppUrl}/api/v0/users/requestdemo?${action.payload.params}`, 'POST', true, action.payload.body))
    .map(response => requestDemoSuccess(response))
    .catch(error => Observable.of({
      type: REQUEST_DEMO_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function requestDemoReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case REQUEST_DEMO: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case REQUEST_DEMO_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data || {},
        loading: false,
        error: false,
        flag: true
      }
    }
    case REQUEST_DEMO_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
