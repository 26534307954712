import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { modalCloseAction } from '../Modal/logic'
import { Premium, Modal } from '../../components'
import BookmarkModal from '../BookmarkModal'
import BibliographyModal from '../BibliographyModal'
import Login from '../Login'
import RequestInvite from '../RequestInvite'
// import SubscriptionsModal from '../../../guidelinewatch/container/SubscriptionsModal';
import Equalizer from '../Equalizer'

class ModalComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      there: ''
    }
  }
  renderType () {
    switch (this.props.modal.type) {
      case 'premium':
        return <Premium />
      case 'login':
        return <Login height={500} app={this.props.app} />
      case 'create_bookmark':
        return <BookmarkModal type='create' params={this.props.modal.data} />
      case 'delete_bookmark':
        return <BookmarkModal type='delete' params={this.props.modal.data} />
      case 'create_bibliography':
        return <BibliographyModal type='create' params={this.props.modal.data} />
      case 'delete_bibliography':
        return <BibliographyModal type='delete' params={this.props.modal.data} />
      case 'request_invite':
        return <RequestInvite height={500} />
      // case 'notificationSubscription':
      //   return <SubscriptionsModal data={this.props.modal.data} />
      case 'equalizer':
        return <Equalizer data={this.props.modal.data} />
      default:
        return null
    }
  }
  render () {
    const {
      dialog, open, size, className
    } = this.props.modal
    return (
      <Modal dialog={dialog} open={open} onClose={this.props.modalCloseAction} size={size} className={className || ''}>
        {this.state.there}
        {this.renderType()}
      </Modal>
    )
  }
}

ModalComponent.propTypes = {
  modal: PropTypes.object.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  app: PropTypes.string
}
ModalComponent.defaultProps = {
  app: 'facelift'
}

const mapStateToProps = state => ({
  modal: state.modal
})

export default connect(mapStateToProps, { modalCloseAction })(ModalComponent)
