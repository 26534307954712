import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';

const RecentEvents = (props) => {
  const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <Fragment>
      {props.data.Abstract && (
      <div className='card-body-content'>
        <div className='sub-title' ><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
        <div id='annotation-highlight-1'>{ReactHtmlParser(abstract)}</div>
      </div>)}
      <div className='card-body-content'>
        { props.data['Grant ID'] && (<div><span><FormattedMessage id="Grant ID" defaultMessage="Grant ID" />:</span>:{props.data['Grant ID']} </div>)}
        {props.data['Data Source'] && (<div><span><FormattedMessage id="Source" defaultMessage="Source" /></span>:{props.data['Data Source']}</div>)}
      </div>
    </Fragment>
  )
}

RecentEvents.propTypes = {
  data: PropTypes.object.isRequired
}

export default RecentEvents
