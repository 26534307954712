import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stringify } from 'query-string'
import PropTypes from 'prop-types'
import { requestDemoAction } from './logic'
import { getCookie } from '../../utils'

class RequestDemo extends Component {
  requestDemo = () => {
    this.props.requestDemoAction({
      params: stringify({ app: this.props.app }),
      body: {
        user_id: getCookie('userName'),
        requested_app: this.props.requestApp
      }
    })
  }
  render() {
    return (
      <div className='request-demo-btn'>
        <button disabled={this.props.demo.loading} onClick={this.requestDemo} type='button' className='btn-demo-req'>Request Demo</button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  demo: state.requestDemo
})

RequestDemo.propTypes = {
  demo: PropTypes.object.isRequired,
  requestDemoAction: PropTypes.func.isRequired,
  app: PropTypes.string,
  requestApp: PropTypes.string,
}

RequestDemo.defaultProps = {
  app: 'ontosight-master',
  requestApp: 'Discover'
}

export default connect(mapStateToProps, { requestDemoAction })(RequestDemo)
