import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { CheckboxGroup, Loader, NoDataFound } from '../../components'
import { modalCloseAction } from '../Modal/logic'
import { bibliographyFolderFetchAction, createBibliographyFolderAction, createBibliographyAction, deleteBibliographyAction, editBibliographyAction } from './logic'
import { setAnalytics } from '../../utils'

class BibliographyModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      folders: [],
      active: [],
      input: '',
      from: 1,
      flag: false,
      size: 200
    }
    this.onChange = this.onChange.bind(this)
    this.onClickOutsideDuringSearch = this.onClickOutsideDuringSearch.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onEnter = this.onEnter.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onCreate = this.onCreate.bind(this)
  }
  componentDidMount () {
    if (this.props.params.id) {
      this.props.bibliographyFolderFetchAction(stringify({
        doc_id: this.props.params.id,
        from: this.state.from,
        size: this.state.size
      }))
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.folders.flag !== this.props.folders.flag && nextProps.folders.flag) {
      this.setState({
        folders: nextProps.folders.data,
        active: nextProps.folders.data.filter(item => item.flag).map(item => item.folder_name)
      })
    }
    if (nextProps.create.flag !== this.props.create.flag && nextProps.create.flag) {
      const a = {
        ...nextProps.create.data,
        flag: true
      }
      const b = JSON.parse(JSON.stringify([a, ...this.state.folders]))
      const c = JSON.parse(JSON.stringify(this.state.active))
      c.push(nextProps.create.data.folder_name)
      this.setState({
        folders: b,
        active: c,
        input: '',
        flag: false
      })
    }
    if (nextProps.createBibliography.flag !== this.props.createBibliography.flag && nextProps.createBibliography.flag) {
      this.props.modalCloseAction()
    }
    if (nextProps.editBibliography.flag !== this.props.editBibliography.flag && nextProps.editBibliography.flag) {
      this.props.modalCloseAction()
    }
    if (nextProps.deleteBibliography.flag !== this.props.deleteBibliography.flag && nextProps.deleteBibliography.flag) {
      this.props.modalCloseAction()
    }
  }
  onChange(active) {
    this.setState({
      active
    })
  }
  onInputChange (e) {
    this.setState({
      input: e.target.value
    })
  }
  onEnter (e) {
    if (e.key === 'Enter') {
      this.onCreate()
    }
  }
  onCreate() {
    if (this.state.input.trim().length > 0) {
      setAnalytics('createBibliographyFolderTrigger', 'createBibliographyFolder', JSON.stringify({
        folder_name: this.state.input.trim()
      }))
      this.props.createBibliographyFolderAction({
        folder_name: this.state.input.trim()
      })
    }
  }
  onSave () {
    if (this.props.type === 'create') {
      setAnalytics('addToBibliographyTrigger', 'addToBibliography', JSON.stringify({
        folder_name: this.state.active,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      }))
      this.props.createBibliographyAction({
        folder_name: this.state.active,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      })
    } else {
      const folderId = []
      for (let i = 0; i < this.state.folders.length; i += 1) {
        if (this.state.active.indexOf(this.state.folders[i].folder_name) > -1) {
          folderId.push(this.state.folders[i].folder_id)
        }
      }
      setAnalytics('editBibliographyTrigger', 'editBibliography', JSON.stringify({
        folder_id: folderId,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      }))
      this.props.editBibliographyAction({
        folder_id: folderId,
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      })
    }
  }
  onDelete () {
    const {
      editBibliography, deleteBibliography, createBibliography, create
    } = this.props
    const loading = editBibliography.loading || deleteBibliography.loading || createBibliography.loading || create.loading
    if (!loading) {
      setAnalytics('deleteBibliographyFromAllTrigger', 'deleteBibliographyFromAll', JSON.stringify({
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      }))
      this.props.deleteBibliographyAction({
        folder_id: [],
        dataset: this.props.params.dataset,
        doc_id: this.props.params.id,
        group: this.props.params.group
      })
    }
  }
  onClickOutsideDuringSearch() {
    if (!this.props.create.loading) {
      this.setState({
        input: '',
        flag: false
      })
    }
  }
  renderAdd () {
    if (this.state.flag) {
      return (
        <div
          style={{
            width: '100%', position: 'relative', display: 'flex', alignItems: 'center', height: 30
          }}
        >
          <input maxLength={30} autoFocus className='potli-input' placeholder='Bibliography name' value={this.state.input} onKeyPress={this.onEnter} onChange={this.onInputChange} />
          {this.state.input.trim().length > 0 && <i role='presentation' className='enter-icon' onClick={this.onCreate} />}
          <i role='presentation' className='close-icon' onClick={this.onClickOutsideDuringSearch} />
        </div>
      )
    }
    return (
      <div role='presentation' onClick={() => this.setState({ flag: true })} className='disp-flex' >
        <a><FormattedMessage id="Create new bibliography" defaultMessage="Create new bibliography" /></a>
      </div>
    )
  }
  renderCheckbox () {
    if (this.state.folders.length === 0) {
      return <NoDataFound className='no-potli' message='No bibliography available' />
    }
    return <CheckboxGroup id='biblograpghy-folders-id' options={this.state.folders.map(item => item.folder_name)} active={this.state.active} onChange={this.onChange} />
  }
  render () {
    const { editBibliography, deleteBibliography, createBibliography } = this.props
    const loading = editBibliography.loading || deleteBibliography.loading || createBibliography.loading
    return (
      <Fragment>
        <div className='modal-header'>
          <div className='modal-title'><FormattedMessage id="Add to Bibliography" defaultMessage="Add to Bibliography" /></div>
        </div>
        <div className='modal-body'>
          <Loader height={150} loading={this.props.folders.loading} error={this.props.folders.error}>
            {this.renderAdd()}
            <div className='bookmark-modal-folders scrollbar'>
              {this.renderCheckbox()}
            </div>
          </Loader>
        </div>
        <div className='modal-footer has-space-btwn'>
          {this.props.type === 'delete' && <button disabled={this.props.create.loading || loading} className='remove-all-btn' onClick={() => this.onDelete()}><FormattedMessage id="Remove from all" defaultMessage="Remove from all" /></button>}
          <button className='btn btn-default delete-btn invisible' onClick={this.props.modalCloseAction}>Close</button>
          <button className='btn btn-base delete-btn' disabled={this.state.active.length === 0 || this.props.create.loading || loading} onClick={() => this.onSave()}><FormattedMessage id="Save" defaultMessage="Save" /></button>
        </div>
      </Fragment>
    )
  }
}

BibliographyModal.propTypes = {
  modalCloseAction: PropTypes.func.isRequired,
  folders: PropTypes.object.isRequired,
  createBibliographyFolderAction: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['create', 'delete']).isRequired,
  params: PropTypes.object.isRequired,
  bibliographyFolderFetchAction: PropTypes.func.isRequired,
  create: PropTypes.object.isRequired,
  editBibliography: PropTypes.object.isRequired,
  deleteBibliography: PropTypes.object.isRequired,
  createBibliography: PropTypes.object.isRequired,
  createBibliographyAction: PropTypes.func.isRequired,
  deleteBibliographyAction: PropTypes.func.isRequired,
  editBibliographyAction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  folders: state.bibliographyFolder,
  create: state.createBibliographyFolder,
  editBibliography: state.editBibliography,
  deleteBibliography: state.deleteBibliography,
  createBibliography: state.createBibliography
})

export default connect(mapStateToProps, {
  modalCloseAction,
  bibliographyFolderFetchAction,
  createBibliographyFolderAction,
  createBibliographyAction,
  deleteBibliographyAction,
  editBibliographyAction
})(BibliographyModal)
