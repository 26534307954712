import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { purchaseFetchAction } from './logic';

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    }
  }

  componentWillMount() {
    const reqStatusObj = {};
    this.state.data.request_status.forEach((requestStatus) => {
      this.state.data.author_valuation.forEach((licenseInfo) => {
        if (licenseInfo.license_type.id === requestStatus.license_type) {
          reqStatusObj[licenseInfo.license_type.type] = requestStatus;
        }
      });
      if (requestStatus.request_status === 'C') {
        this.setState(() => ({
          isPurchased: true
        }));
      }
    });
    this.setState(() => ({
      reqStatus: reqStatusObj
    }));
  }

  /* componentWillReceiveProps(nextProps) {
    if (nextProps.purchase.flag !== this.props.purchase.flag && nextProps.purchase.flag) {
      const data = {...this.state.data}
      this.state.data.author_valuation.forEach((license) => {
          if (license.license_type.type === 'Exclusive') {
            docInfo.license_type = license.license_type.id;
            docInfo.document_collection = this.state.data.id;
           // payload.push(docInfo);
          }
          if (license.license_type.type === 'Shared') {
            docInfo.license_type = license.license_type.id;
            docInfo.document_collection = this.state.data.id;
            //payload.push(docInfo);
          }
      });

    }
  } */

  onClickBuy = (selectedLicenseType) => {
    const payload = [];
    const docInfo = {};
    if (selectedLicenseType.type === 'Shared') {
      docInfo.license_type = selectedLicenseType.id;
      docInfo.document_collection = this.state.data.id;
      payload.push(docInfo);
    }
    if (selectedLicenseType.type === 'Exclusive') {
      docInfo.license_type = selectedLicenseType.id;
      docInfo.document_collection = this.state.data.id;
      payload.push(docInfo);
    }
    this.props.purchaseFetchAction(payload);

    let reqStatus = {};
    reqStatus = this.state.reqStatus ? this.state.reqStatus : {};
    reqStatus[selectedLicenseType.type] = reqStatus[selectedLicenseType.type] ? reqStatus[selectedLicenseType.type] : {};
    reqStatus[selectedLicenseType.type].request_status = 'P';

    this.setState(() => ({
      reqStatus
    }));
  };


  onPay = (requestData) => {
    const experimentId = requestData.document_collection;
    const licenseId = requestData.license_type;
    const url = `marketplace/#/home/initiateBuy/?experiment_id=${experimentId}&license_id=${licenseId}`;
    window.location.assign(url);
  };

  isIntrinsicsFirstValuesDefined = () => {
    const intrinsic = this.state.data.intrinsic_value;
    if (intrinsic.length > 0
      &&
      intrinsic[0].license_type.type
      &&
      intrinsic[0].intrinsic_value_from
      &&
      intrinsic[0].intrinsic_value_to
    ) {
      return true;
    }
    return false;
  };

  isIntrinsicsSecondValuesDefined = () => {
    const intrinsic = this.state.data.intrinsic_value;
    if (intrinsic.length > 1
      &&
      intrinsic[1].license_type.type
      &&
      intrinsic[1].intrinsic_value_from
      &&
      intrinsic[1].intrinsic_value_to) {
      return true;
    }
    return false;
  };


  viewDetails = () => {
    window.location.assign('/marketplace/#/home/licensePurchased/');
  };


  render() {
    return (
      <div className='purchase-data card-body-content'>
        {
          this.state.isPurchased
            ?
              <div className='card-body-content'>
                <a
                  className='view-details-link'
                  role='presentation'
                  onClick={this.viewDetails}
                >
                  <FormattedMessage id="View details" defaultMessage="View details" />
                </a>
              </div>
            :
              <div className='card-body-content'>
                <div className='sub-title'>
                  <FormattedMessage id="Purchase Document" defaultMessage="Purchase Document" />
                </div>
                <div className='sub-in-title'>
                  <FormattedMessage id="Select license type" defaultMessage="Select license type" />
                </div>
                {
                  this.state.data.author_valuation.length > 0 && this.state.data.author_valuation.map((authLicense, index) => (
                    <div className='card-body-content' key={index}>
                      <div className='purchase-option'>
                        {authLicense.license_type.type}
                        (${authLicense.valuation} <FormattedMessage id="per license" defaultMessage="per license" />)
                      </div>
                      {
                        this.state.reqStatus
                        ?
                          <div>
                            {
                              (this.state.reqStatus[authLicense.license_type.type] && this.state.reqStatus[authLicense.license_type.type].request_status === 'P')
                                ?
                                  <div>
                                    <div className='req-pen-btn'>
                                      <FormattedMessage id="REQUEST PENDING" defaultMessage="REQUEST PENDING" />
                                    </div>
                                    <div className='req-pen-notify'>
                                      (<FormattedMessage id="We’ll notify you when the status of your request changes" defaultMessage="We’ll notify you when the status of your request changes" />)
                                    </div>
                                  </div>
                                :
                                  (this.state.reqStatus[authLicense.license_type.type] && this.state.reqStatus[authLicense.license_type.type].request_status === 'R')
                                    ?
                                      <div>
                                        <div className='req-decline-notify'>
                                          <FormattedMessage id="Request decline" defaultMessage="Request decline" />
                                        </div>
                                        {/* <div role='presentation' className='req-decline-btn' onClick={this.onClickRequestAgain}>
                                          REQUEST AGAIN
                                        </div> */}
                                      </div>
                                    :
                                      (this.state.reqStatus[authLicense.license_type.type] && this.state.reqStatus[authLicense.license_type.type].request_status === 'A')
                                        ?
                                          <div role='presentation' className='buy-now-btn hand' onClick={() => this.onPay(this.state.reqStatus[authLicense.license_type.type])}>
                                            <FormattedMessage id="BUY NOW" defaultMessage="BUY NOW" />
                                          </div>
                                        :
                                          <div role='presentation' className='buy-now-btn req-to-buy hand' onClick={() => this.onClickBuy(authLicense.license_type)}>
                                            <FormattedMessage id="REQUEST TO BUY" defaultMessage="REQUEST TO BUY" />
                                          </div>
                            }
                          </div>
                        :
                          <div role='presentation' className='buy-now-btn req-to-buy hand' onClick={() => this.onClickBuy(authLicense.license_type)}>
                            <FormattedMessage id="REQUEST TO BUY" defaultMessage="REQUEST TO BUY" />
                          </div>
                      }
                    </div>
                ))
                }
              </div>
        }
        {
          this.isIntrinsicsFirstValuesDefined()
          &&
          <div className='purchase-description card-body-content'>
            <FormattedMessage id="Based on our AI based valuation engine, the license is fairly priced." defaultMessage="Based on our AI based valuation engine, the license is fairly priced." /> {this.state.data.intrinsic_value[0].license_type.type} <FormattedMessage id="license to similar documents costs around" defaultMessage="license to similar documents costs around" /> ${this.state.data.intrinsic_value[0].intrinsic_value_from} - ${this.state.data.intrinsic_value[0].intrinsic_value_to}
            {
              this.isIntrinsicsSecondValuesDefined()
              &&
              <div>
                & {this.state.data.intrinsic_value[1].license_type.type} <FormattedMessage id="license to similar documents costs around" defaultMessage="license to similar documents costs around" /> ${this.state.data.intrinsic_value[1].intrinsic_value_from} - ${this.state.data.intrinsic_value[1].intrinsic_value_to}.
              </div>
            }
            <FormattedMessage id="Please note that this information is only suggestive and determining the final selling price for a submission is the prerogative of its author." defaultMessage="Please note that this information is only suggestive and determining the final selling price for a submission is the prerogative of its author." />
          </div>
        }
      </div>
    );
  }
}

Purchase.propTypes = {
  data: PropTypes.object.isRequired,
  purchaseFetchAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  purchase: state.purchase
});

export default withRouter(connect(mapStateToProps, { purchaseFetchAction })(Purchase));
