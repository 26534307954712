import React from 'react';
import RequestDemo from '../../../common/container/RequestDemo';
import CheckAccessToken from '../../../common/container/CheckAccessToken'
import { getCookie } from '../../../common/utils';

const Dashboard = () => (
  <div className='dashboard page-layout'>
    <div className='top-landing-head'>
      <div className='section section-one'>
        <div className='content left-content'>
          <div className='logo-page logo-dashboard' />
          <div className='content-page-title'>
            Focus on decision making rather than data accumulation
            {/* Focus on decision making rather than data accumulation */}
          </div>
          <div className='content-page-info'>
            <span>Ontosight<sup>® </sup></span>Dashboards empower teams involved at various stages of drug development with AI-enabled custom insights and updates to make strategic decisions. Let the machine do the data heavy lifting, so you can accelerate every step with deeper insights for specific objectives. Get real-time updates on patents, competitive intelligence, treatment guidelines, regulatory requirements, and medical affairs with our unique, intuitive dashboards. Why go searching everywhere when there could be a dashboard tailored to your specific needs!
          </div>
          <RequestDemo requestApp='Dashboard' />
        </div>
        <div className='content right-content'>
          <div className='img-right-top dashboard-welcome-img' />
        </div>
      </div>
      <div className='section section-two key-feature'>
        <div className='content center-content'>
          <div className='content-page-title content-page-center-title'>
            Highlights
          </div>
          <div className='page-title-features'>
          Be ahead of everyone else with continuous AI-enabled insights
          </div>
          <div className='content-page-info content-page-center-info'>
            <div className='key-list'>Get dashboards for any and all business functions such as Medical Affairs, Regulatory, Competitive Intelligence, R&D, Clinical, Commercial, Business Development, and Management
            </div>
            <div className='key-list'>Significant time-savings and reduction in mundane repetitive tasks through AI-powered aggregation and intuitive visualization of insights, updated from over 95% of public web, enterprise, and third-party data sets
            </div>
            <div className='key-list'>Timely updates and real-time notifications to make sure you do not miss out on an important business decision
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='page-container'>
      <div className='section section-two' />
      <div className='section section-three section-three-one'>
        <div className='content img-content-in'>
          <div className='img-left dashboard-img-one' />
        </div>
        <div className='content text-content-in'>
          <div className='content-page-title content-page-in-title'>
            Medical Affairs
          </div>
          <div className='content-page-info'>
            Get relevant insights on clinical trials, guidelines, and label revisions regarding indications and interventions of interest with AI-powered dashboards that leverage data from over 100 trial registries, major regulatory bodies, and scientific societies across the globe. Accelerate processes for medical affairs and empower them with real-time updates for informed and timely decision-making.
          </div>
        </div>
      </div>

      <div className='section section-three section-three-two'>
        <div className='content text-content-in text-two'>
          <div className='content-page-title content-page-in-title'>
            Regulatory Updates
          </div>
          <div className='content-page-info'>
            Relevant updates for regulatory information of drug Get real-time and relevant updates around regulatory submission of a drug from major regulatory bodies regarding indication of interest
          </div>
        </div>
        <div className='content img-content-in'>
          <div className='img-right dashboard-img-two' />
        </div>
      </div>

      <div className='section section-three section-three-three'>
        <div className='content img-content-in'>
          <div className='img-left dashboard-img-three' />
        </div>
        <div className='content text-content-in text-three'>
          <div className='content-page-title content-page-in-title'>
            Sentiment Watch
          </div>
          <div className='content-page-info'>
            Track sentiments from digital and scientific media Track real-time buzz on sponsors, and stay abreast of SOV across drugs of interest, sentiment momentum, hot topics, and weak signals from clinical or digital media
          </div>
        </div>
      </div>

      <div className='section section-three section-three-four'>
        <div className='content text-content-in text-two'>
          <div className='content-page-title content-page-in-title'>
            Guideline Watch
          </div>
          <div className='content-page-info'>
            Relevant updates from treatment guidelines around an indication Get insights and relevant updates on published treatment guidelines for your indication of interest
          </div>
        </div>
        <div className='content img-content-in'>
          <div className='img-right dashboard-img-four' />
        </div>
      </div>

      <div className='section section-three section-three-three'>
        <div className='content img-content-in'>
          <div className='img-left dashboard-img-five' />
        </div>
        <div className='content text-content-in text-three'>
          <div className='content-page-title content-page-in-title'>
            Competitive Landscape

          </div>
          <div className='content-page-info'>
            Real-time intelligence on competitor drugs for each therapeutic area Get detailed analysis of publications, clinical trials, and regulatory approvals for that are in clinical development
          </div>
        </div>
      </div>


      <div className='section section-three section-three-four'>
        <div className='content text-content-in text-two'>
          <div className='content-page-title content-page-in-title'>
            My Dashboard

          </div>
          <div className='content-page-info'>
            Define your own widgets Select your own data points to generate widgets that deliver customized insights on your topics of interest
          </div>
        </div>
        <div className='content img-content-in'>
          <div className='img-right dashboard-img-six' />
        </div>
      </div>

      <div className='section section-four'>
        <div className='content connect-data-img  connect-data-img-dashboard'>
          <div className='img-left' />
          <div className='data-text-content'>
            <div className='logo-page logo-dashboard logo-dashboard-bottom' />
            <div className='text-logo'>Get the right information in a click with our Ontosight<sup>®</sup> Dashboard</div>
            <RequestDemo requestApp='Dashboard' />
          </div>
        </div>
      </div>
    </div>
  </div>
)


export default CheckAccessToken(!getCookie('accessToken'))(Dashboard)
