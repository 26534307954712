import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

const SEARCHTREE_FETCH = 'SEARCHTREE_FETCH'
const SEARCHTREE_UPDATE_FETCH_CANCEL = 'SEARCHTREE_UPDATE_FETCH_CANCEL'
const SEARCHTREE_FETCH_SUCCESS = 'SEARCHTREE_FETCH_SUCCESS'
const SEARCHTREE_UPDATE_FETCH = 'SEARCHTREE_UPDATE_FETCH'
const SEARCHTREE_UPDATE_FETCH_SUCCESS = 'SEARCHTREE_UPDATE_FETCH_SUCCESS'
const SEARCHTREE_FETCH_CANCEL = 'SEARCHTREE_FETCH_CANCEL'
const SEARCHTREE_FETCH_FAILURE = 'SEARCHTREE_FETCH_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const searchTreeFetchAction = payload => ({
  type: SEARCHTREE_FETCH,
  payload
})

const searchTreeFetchSuccess = payload => ({
  type: SEARCHTREE_FETCH_SUCCESS,
  payload
})

export const searchTreeUpdateFetchAction = payload => ({
  type: SEARCHTREE_UPDATE_FETCH,
  payload
})

const searchTreeUpdateFetchSuccess = payload => ({
  type: SEARCHTREE_UPDATE_FETCH_SUCCESS,
  payload
})

export const searchTreeUpdateFetchCancel = payload => ({
  type: SEARCHTREE_UPDATE_FETCH_CANCEL,
  payload
})

export const searchTreeFetchCancel = payload => ({
  type: SEARCHTREE_FETCH_CANCEL,
  payload
})

export const searchTreeFetchEpic = action$ => action$
  .ofType(SEARCHTREE_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/tree?${action.payload.params}`, 'POST', true, action.payload.postData))
    .map(response => searchTreeFetchSuccess(response))
    .takeUntil(action$.ofType(SEARCHTREE_FETCH_CANCEL))
    .catch(error => Observable.of({
      type: SEARCHTREE_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))
export const searchTreeUpdateFetchEpic = action$ => action$
  .ofType(SEARCHTREE_UPDATE_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/tree?${action.payload.params}`, 'POST', true, action.payload.postData))
    .map(response => searchTreeUpdateFetchSuccess(response))
    .takeUntil(action$.ofType(SEARCHTREE_UPDATE_FETCH_CANCEL))
    .catch(error => Observable.of({
      type: SEARCHTREE_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function searchTreeFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case SEARCHTREE_FETCH: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case SEARCHTREE_UPDATE_FETCH: {
      return {
        ...state,
        data: [],
        loading: false,
        error: false,
        flag: false
      }
    }
    case SEARCHTREE_FETCH_SUCCESS: {
      const data = action.payload.response.data.map(item => ({ ...item, ...Object.keys(item).indexOf('disabled') === -1 ? { disabled: false } : {} }))
      return {
        ...state,
        data: [...data],
        loading: false,
        error: false,
        flag: true
      }
    }
    case SEARCHTREE_UPDATE_FETCH_SUCCESS: {
      const data = action.payload.response.data.map(item => ({ ...item, ...Object.keys(item).indexOf('disabled') === -1 ? { disabled: false } : {} }))
      return {
        ...state,
        data: [...data],
        loading: false,
        error: false,
        flag: true,
      }
    }
    case SEARCHTREE_FETCH_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
