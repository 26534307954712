import { combineReducers } from 'redux'
import commonReducers from '../../common/container/reducers'
import { autoCompleteReducer, forceRefreshReducer } from './SearchBar/logic'
import { groupFetchReducer } from './Groups/logic'
import { productFetchReducer } from './Products/logic'
import { sponsorFetchReducer } from './Sponsors/logic'
import { hookFetchReducer } from './Hooks/logic'
import { filterConfigReducer, filterReducer, filterSearchReducer } from './FilterConfig/logic'
import { initialQueryFormatReducer } from './AdvancedSearch/logic'
import { advancedSearchAutoCompleteReducer } from './CategoryFilter/logic'
import { wordcloudFetchReducer } from './Concepts/logic'
import { getSubGraphReducer } from './WordCloud/logic'
import { sortByFetchReducer } from './SortBy/logic'
import { trendsReducer } from './Trends/logic'
import { purchaseFetchReducer } from './Purchase/logic'
import { kolCategoriesFetchReducer, kolCategoriesActiveReducer } from './Kols/KolCategories'
import { kolAuthorsIdsFetchReducer, kolsFiltersReducer, kolsCountriesFiltersReducer, kolTypeFilterReducer } from './Kols/KolAuthorsIds'
import { kolResultsFetchReducer } from './Kols/KolAuthorResults'
import { searchTreeFetchReducer } from './SearchTree/logic'
import { wordcloudHookFetchReducer } from './ViewDetails/logic'
import { saveClusterFetchReducer } from './SaveAsCluster/logic'
import { rawdataListReducer, rawdataCountReducer, rawdataInnerReducer } from './RawDataResults/logic'
import { downloadReducer } from './Download/logic'
import { moaReducer, moaDrugsReducer } from './MOA/logic'
import { searchEqualizerReducer } from './SearchEqualizer/logic'
import { editableAutoCompleteReducer, createHistoryReducer, addSearchTermReducer } from './EditableSearch/logic'
import { advancedEditableAutoCompleteReducer } from './AdvanceSearchCategory/logic'
import { summaryFetchReducer } from './Summary/logic'
import { graphDataFiltersReducer } from './summaryGraph/logic'

const rootReducer = combineReducers({
  editableAutoComplete: editableAutoCompleteReducer,
  advancedEditableAutoComplete: advancedEditableAutoCompleteReducer,
  autoComplete: autoCompleteReducer,
  groups: groupFetchReducer,
  products: productFetchReducer,
  sponsors: sponsorFetchReducer,
  hooks: hookFetchReducer,
  ...commonReducers,
  addSearch: addSearchTermReducer,
  filterConfig: filterConfigReducer,
  filter: filterReducer,
  searchTree: searchTreeFetchReducer,
  filterSearch: filterSearchReducer,
  initialQuery: initialQueryFormatReducer,
  advancedSearchAutoComplete: advancedSearchAutoCompleteReducer,
  wordcloud: wordcloudFetchReducer,
  wordcloudHooks: wordcloudHookFetchReducer,
  graph: getSubGraphReducer,
  sortByOptions: sortByFetchReducer,
  trends: trendsReducer,
  purchase: purchaseFetchReducer,
  kolCategories: kolCategoriesFetchReducer,
  kolAuthorsIds: kolAuthorsIdsFetchReducer,
  kolResults: kolResultsFetchReducer,
  kolsFilters: kolsFiltersReducer,
  kolsCountriesFilters: kolsCountriesFiltersReducer,
  kolTypeFilter: kolTypeFilterReducer,
  forceRefresh: forceRefreshReducer,
  saveCluster: saveClusterFetchReducer,
  history: createHistoryReducer,
  rawdataList: rawdataListReducer,
  downloadLink: downloadReducer,
  rawdataCount: rawdataCountReducer,
  rawdataInner: rawdataInnerReducer,
  moa: moaReducer,
  moadrugs: moaDrugsReducer,
  searchEqualizer: searchEqualizerReducer,
  kolCategoriesActive: kolCategoriesActiveReducer,
  summaryFetch: summaryFetchReducer,
  graphData: graphDataFiltersReducer,
})

export default rootReducer
