import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Rcookie from 'react-cookies'
import { stringify } from 'query-string'
import { CheckboxGroup } from 'reusable-react-components'
import { connect } from 'react-redux'
import annotator from 'annotator'
import { injectIntl } from 'react-intl'
import { getCookie, checkPermission, setAnalytics } from '../../utils'
import { annotationFetchAction } from './logic'
import { customMessage } from '../Status/logic'

const guidGenerator = () => {
  const S4 = () => Math.random().toString(36).substring(7)
  return (`${S4()}${S4()}_${S4()}_${S4()}_${S4()}_${S4()}${S4()}${S4()}`)
}

class Annotation extends Component {
  static changeActiveOnSave(options) {
    const { self, custom } = options
    return {
      annotationCreated: () => {
        if (self.props.annotationDependent) {
          self.onChange([self.props.intl.formatMessage({ id: 'Show annotations', defaultMessage: 'Show annotations' })], 'active')
        }
        custom({
          message: 'Annotation has been created successfully',
          status: 'success',
          title: 'Success'
        })
        setAnalytics('annotationCreatedTrigger', 'annotationCreated', self.props.id)
      },
      annotationDeleted: () => {
        if (self.props.annotationDependent) {
          self.onChange([self.props.intl.formatMessage({ id: 'Show annotations', defaultMessage: 'Show annotations' })], 'active', true)
        }
        custom({
          message: 'Annotation has been deleted successfully',
          status: 'success',
          title: 'Success'
        })
        setAnalytics('annotationDeletedTrigger', 'annotationDeleted', self.props.id)
      },
      annotationUpdated: (value) => {
        custom({
          message: 'Annotation has been updated successfully',
          status: 'success',
          title: 'Success'
        })
        setAnalytics('annotationUpdatedTrigger', 'annotationUpdated', JSON.stringify(value))
      }
    }
  }
  constructor(props) {
    super(props)
    this.state = {
      active: [this.props.intl.formatMessage({ id: 'Show annotations', defaultMessage: 'Show annotations' })]
    }
    this.onChange = this.onChange.bind(this)
    this.app = null
  }
  componentDidMount() {
    this.onChange()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.annotation.flag !== this.props.annotation.flag && nextProps.annotation.flag) {
      if (this.app) {
        this.app.annotations.loadManual(nextProps.annotation.data.data)
      }
    }
  }
  componentWillUnmount() {
    if (this.app) {
      this.app.destroy()
    }
  }
  onChange(value, key, flag = false) {
    if (!flag) {
      if (key === 'active') {
        if (value.length) {
          setAnalytics('showAnnotationCheckboxClickTrigger', 'showAnnotationCheckboxClick', 'Checked Show Annotations');
        } else {
          setAnalytics('showAnnotationCheckboxClickTrigger', 'showAnnotationCheckboxClick', 'Unchecked Show Annotations');
        }
      }
    }
    this.setState({
      [key]: value
    }, () => {
      if (!checkPermission('ontosight_academics_user')) {
        if (document.querySelector('#annotation-highlight-1')) {
          if (this.app) {
            this.app.destroy()
            this.app = new annotator.App()
          } else {
            this.app = new annotator.App()
          }
          this.annotationInit()
          if (this.state.active.length) {
            this.props.annotationFetchAction({
              params: stringify({
                doc_id: this.props.id,
                dataset: this.props.dataset,
                group: this.props.group
              }),
              id: this.props.id
            })
          } else {
            this.app.annotations.loadManual([])
          }
        }
      }
    })
  }
  annotationInit() {
    const self = this
    const pageUri = () => ({
      beforeAnnotationCreated: (ann) => {
        const config = ann
        config.uri = encodeURIComponent(window.location.href)
        config.annotation_id = guidGenerator()
        config.doc_id = self.props.id
        config.user_id = getCookie('userName')
        config.user = ''
        config.dataset = self.props.dataset
        config.group = self.props.group
      }
    })
    this.app.include(annotator.ui.main, {
      element: document.querySelector('#annotation-highlight-1')
    })
      .include(pageUri)
      .include(annotator.storage.debug)
      .include(annotator.storage.http, {
        prefix: `${process.env.apiUrl}v1/highlights`,
        urls: {
          create: '/',
          destroy: '/delete/?annotation_id={id}',
          // search: '?apiKey=NfffJPK4idTah_JmhYQk_AQ6SOMdRi34',
          update: '/edit/?annotation_id={id}'
        },
        headers: {
          Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
    this.app.include(Annotation.changeActiveOnSave, {
      self: this,
      custom: this.props.customMessage
    })
    this.app.start()
  }
  render() {
    if (checkPermission('ontosight_academics_user')) {
      return null
    }
    return (
      <div>
        <CheckboxGroup inline id='annotation-checkbox' options={[this.props.intl.formatMessage({ id: 'Show annotations', defaultMessage: 'Show annotations' })]} active={this.state.active} onChange={value => this.onChange(value, 'active')} />
      </div>
    )
  }
}

Annotation.propTypes = {
  id: PropTypes.string.isRequired,
  annotation: PropTypes.object.isRequired,
  annotationFetchAction: PropTypes.func.isRequired,
  customMessage: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  dataset: PropTypes.string,
  group: PropTypes.string
}

Annotation.defaultProps = {
  dataset: '',
  group: 'Published'
}

Annotation.defaultProps = {
}

const mapStateToProps = state => ({
  annotation: state.annotationFetch
})

export default connect(mapStateToProps, { annotationFetchAction, customMessage })(injectIntl(Annotation))
