import React, { Component, Fragment } from 'react'
import ToolTip from 'react-portal-tooltip'
import PropTypes from 'prop-types'
import { style, generateRandomString } from '../../utils/index'

class InfoToolTip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipActive: false
    }
  }
  render() {
    const custom = {
      style: {
        ...style.style,
        'z-index': this.props.zIndex,
        marginTop: 3,
        ...(this.props.type === 'white' ? { background: '#ffffff', color: 'black' } : {})
      },
      arrowStyle: {
        ...style.arrowStyle,
        ...(this.props.type === 'white' ? { color: '#ffffff' } : {})
      }
    }
    return (
      <Fragment>
        {this.props.infoText && <span id={this.props.id} onBlur={() => null} onMouseEnter={() => this.setState({ isTooltipActive: true })} onMouseLeave={() => this.setState({ isTooltipActive: false })} className={this.props.type === 'white' ? 'info-icon-white' : 'info-icon'} />}
        <ToolTip tooltipTimeout={0} arrow='center' style={custom} active={this.state.isTooltipActive} position='left' parent={`#${this.props.id}`}>
          {this.props.infoText}
        </ToolTip>
      </Fragment>

    )
  }
}

InfoToolTip.propTypes = {
  infoText: PropTypes.string.isRequired,
  type: PropTypes.string,
  zIndex: PropTypes.string,
  id: PropTypes.string,
}

InfoToolTip.defaultProps = {
  zIndex: '500',
  type: 'black',
  id: generateRandomString()
}

export default InfoToolTip
