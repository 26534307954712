import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import { Loader } from '../../components'
import Drawer from '../../../common/container/Drawer'
import { Error404 } from '../../../common/components'
import AcademicsLanding from '../AcademicsLanding'
import BrowserSupportPage from '../../../common/components/BrowserSupportPage'


const SearchPage = Loadable({
  loader: () => import('../Search'),
  loading() {
    return <Loader error={false} loading />
  }
})


const BoardsPage = Loadable({
  loader: () => import('../Boards'),
  loading() {
    return <Loader error={false} loading />
  }
})

const FaqPage = Loadable({
  loader: () => import('../../../common/components/Faq'),
  loading() {
    return <Loader error={false} loading />
  }
})

const LandingPage = Loadable({
  loader: () => import('../../../common/container/Landing'),
  loading() {
    return <Loader error={false} loading />
  }
})

const FullAbstractPage = Loadable({
  loader: () => import('../../../common/container/FullAbstract'),
  loading() {
    return <Loader error={false} loading />
  }
})

const RawDataResults = Loadable({
  loader: () => import('../RawDataResults'),
  loading() {
    return <Loader error={false} loading />
  }
})

const Main = () => (
  <Fragment>
    <Switch>
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/search' component={SearchPage} />
      <Route exact path='/faq' component={FaqPage} />
      <Route exact path='/boards' component={BoardsPage} />
      <Route exact path='/rawdataresult' component={RawDataResults} />
      <Route exact path='/academic' component={AcademicsLanding} />
      <Route exact path='/abstract' component={FullAbstractPage} />
      <Route exact path='/abc' component={BrowserSupportPage} />
      <Route component={Error404} />

    </Switch>
    <Drawer />
  </Fragment>
)

export default Main
