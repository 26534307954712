export function getMonth (timeStamp) {
  return new Date(timeStamp).getMonth()
}
export function getYear (timeStamp) {
  return new Date(timeStamp).getFullYear()
}
export function getNoOfDays (timeStamp) {
  return new Date(getYear(timeStamp), getMonth(timeStamp) + 1, 0).getDate()
}
export function getNoOfDays1 (year, month) {
  return new Date(year, month + 1, 0).getDate()
}
export function getEmptySpace (timeStamp) {
  return new Date(getYear(timeStamp), getMonth(timeStamp), 1).getDay()
}
export function getTimeStamp (...value) {
  return value ? new Date(...value).getTime() : new Date().getTime()
}
export function getDate (timeStamp) {
  return new Date(timeStamp).getDate()
}
export function getMinMaxValue (type, maxFlag, active) {
  if (type === 'date') {
    const min = getTimeStamp(getYear(active), getMonth(active), getDate(active))
    const max = getTimeStamp(getYear(active), getMonth(active), getDate(active), 23, 59, 59)
    return maxFlag ? max : min
  } else if (type === 'month') {
    const min = getTimeStamp(getYear(active), getMonth(active), 1)
    const max = getTimeStamp(getYear(active), getMonth(active), getNoOfDays(active), 23, 59, 59)
    return maxFlag ? max : min
  } else if (type === 'year') {
    const min = getTimeStamp(getYear(active), 0, 1)
    const max = getTimeStamp(getYear(active), 11, 31, 23, 59, 59)
    return maxFlag ? max : min
  }
  return active
}
export function checkActive (type, after, active, year, month, date) {
  const min = getMinMaxValue(type, false, active)
  const monthCheck = typeof (month) !== 'undefined' ? month : after ? 11 : 0
  const dateCheck = typeof (date) !== 'undefined' ? date : after ? new Date(year, monthCheck + 1, 0).getDate() : 1
  const actual = getTimeStamp(year, monthCheck, dateCheck)
  const max = getMinMaxValue(type, true, active)
  return (min <= actual) && (actual <= max)
}
export function checkDisable (type, after, disable, year, month, date) {
  if (typeof (disable) !== 'undefined') {
    const value = getMinMaxValue(type, after, disable)
    const monthCheck = typeof (month) !== 'undefined' ? month : after ? 11 : 0
    const dateCheck = typeof (date) !== 'undefined' ? date : after ? new Date(year, monthCheck + 1, 0).getDate() : 1
    const actual = getMinMaxValue(type, after, getTimeStamp(year, monthCheck, dateCheck))
    return after ? actual > value : actual < value
  }
  return false
}
export function formatDate (value, format = { day: '2-digit', month: 'short', year: 'numeric' }) {
  return Intl.DateTimeFormat('en-US', format).format(new Date(value))
}
export const disableBeforeDate = (type = 'year') => getMinMaxValue(type, false, new Date('1971-1-1').getTime())
export const disableAfterDate = (type = 'year') => getMinMaxValue(type, true, new Date().getTime())
const now = (type = 'year') => getMinMaxValue(type, true, getTimeStamp())
const startDate = (value, type = 'year') => getMinMaxValue(type, false, getTimeStamp(getYear(now(type)) - value, getMonth(now(type)), getDate(now(type))))
export const customOptions = (type = 'year') => [{
  name: 'Last 10 years',
  endDate: now(type),
  startDate: startDate(10, type)
}, {
  name: 'Last 5 years',
  endDate: now(type),
  startDate: startDate(5, type)
}, {
  name: 'Last 2 years',
  endDate: now(type),
  startDate: startDate(2, type)
}, {
  name: 'Last 1 year',
  endDate: now(type),
  startDate: startDate(1, type)
}
]
