import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3';

class BubbleBar extends Component {
  constructor(props) {
    super(props);
    this.config = { ...BubbleBar.defaultProps.config, ...this.props.config }
    this.data = this.makedata(this.props.data, this.props.authid);
    this.cfg = JSON.parse(JSON.stringify(this.config));
    this.chartID = Math.floor(Math.random(10000) * 10000) + 1;
    this.margin = {
      top: this.cfg.marginTop,
      right: this.cfg.marginRight,
      bottom: this.cfg.marginBottom,
      left: this.cfg.marginLeft
    };
    this.width = this.cfg.width - this.margin.left - this.margin.right;
    this.height = this.cfg.height - this.margin.top - this.margin.bottom;
    this.y = d3.scaleBand().range([this.height, 0]).padding(0.1);
    this.x = d3.scaleLinear().range([0, 100]);
    this.x.domain([0, 100])
    this.y.domain(this.data.map(d => d.name));
    this.renderCircles = this.renderCircles.bind(this);
  }
  componentDidMount() {
    d3.select(`.yaxis-${this.chartID}`).attr('transform', `translate(
        ${this.margin.left - 5}, 
        ${this.margin.top + (this.y.bandwidth() / 10)}
    )`)
      .call(d3.axisLeft(this.y))
      .selectAll('text')
      .attr('class', 'axis-domain')
  }
  makedata = (data, authid) => {
    if (data[0].scores) {
      [data] = data;
      [data] = data.scores.filter(d => d.author_id === authid);
      const scoreProfile = data.score_profile;
      const tempdata = {};
      const scores = [];
      if (!scoreProfile) {
        return [];
      }
      Object.keys(scoreProfile).map((d) => {
        tempdata[d] = {}
        const keys = Object.keys(scoreProfile[d]);
        tempdata[d].totalKeys = keys.length;
        let NewScore = 0;
        keys.map((d1) => {
          const tempCount = scoreProfile[d][d1].counts;
          const tempScore = scoreProfile[d][d1].score;
          NewScore = (tempCount / 100) * tempScore;
          tempdata[d].score = NewScore;
          return d1;
        });
        scores.push({ name: d, value: tempdata[d].score })
        return null;
      });
      return scores;
    }
    return [];
  }
  renderCircles(x, y) {
    return this.data.map((d) => {
      const yVal = y(d.name);
      const width = x(d.value);
      const { radius } = this.config;
      let ir = radius;
      const vals = [];
      while (ir / 2.2 <= width) {
        vals.push(ir);
        ir += radius * 2.2;
      }

      return vals.map((r, i) => {
        let percent = 100;
        if (vals.length - 1 === i) {
          percent = ((r / 2.2) / width) * 100;
        }
        return (
          <g key={i}>
            <linearGradient id={`grad${i}`}>
              <stop offset={`${percent}%`} style={{ stopColor: this.props.colors[0] }} />
              <stop offset={`${percent}%`} style={{ stopColor: this.props.colors[1] }} />
            </linearGradient>
            <circle
              cx={r + (y.bandwidth() / 10)}
              cy={yVal + y.bandwidth()}
              r={radius}
              fill={`url(#grad${i})`}
            />
          </g>
        )
      })
    })
  }
  renderBars(x, y) {
    return this.data.map((d) => {
      const yVal = y(d.name);
      const width = x(d.value);
      return (
        <rect
          height={y.bandwidth()}
          width={width}
          fill='transparent'
          className='filled'
          y={yVal + (y.bandwidth() / 2)}
        />
      )
    })
  }

  renderCirclesEmpty(x, y) {
    return this.data.map((d) => {
      const yVal = y(d.name);
      const width = 190
      const { radius } = this.config;
      let ir = radius;
      const vals = [];
      while (ir < width) {
        vals.push(ir);
        ir += radius * 2.2;
      }
      return vals.map((r, i) => (<circle
        key={i}
        cx={r + (y.bandwidth() / 10)}
        cy={yVal + y.bandwidth()}
        r={radius}
        className='filled'
        fill={this.props.colors[1]}
      />))
    })
  }
  render() {
    this.SVGDimensions = {
      width: this.width + this.margin.left + this.margin.right,
      height: this.height + this.margin.top + this.margin.bottom
    };

    return (
      <div>
        <svg
          width={this.SVGDimensions.width}
          height={this.SVGDimensions.height}
          className={`BarChart${this.chartID}`}
        >
          <g
            transform={`translate(${this.margin.left}, ${this.margin.top - this.config.radius})`}
            className={`g${this.chartID}`}
          >
            <g
              transform='translate(-2,0)'
            >
              {this.renderCirclesEmpty(this.x, this.y, this.data, this.cfg)}
              {this.renderCircles(this.x, this.y, this.data, this.cfg)}
            </g>
          </g>
          <g className={`yaxis-${this.chartID}`} />
        </svg>
      </div >
    )
  }
}
BubbleBar.propTypes = {
  config: PropTypes.object,
  colors: PropTypes.array,
  data: PropTypes.any,
  authid: PropTypes.any.isRequired
};

BubbleBar.defaultProps = {
  colors: [],
  data: [],
  config: {
    radius: 9,
    height: 130,
    width: 328,
    marginTop: 30,
    marginLeft: 100,
    marginRight: 30,
    marginBottom: 30,
    id: 'name',
    value: 'value'
  }
};
export default BubbleBar;
