import { getRedirectUrl } from '../../common/utils'

const url = getRedirectUrl('app', {}, {}, false)

export const Apps = [
  {
    name: 'Discover',
    link: url,
    active: window.location.pathname !== '/boards',
    permission: true
  },
  {
    name: 'Influence',
    link: 'http://192.168.8.180:5002/#!/intro?alreadyLoggedIn=true',
    active: false,
    permission: true
  },
  {
    name: 'Xplore',
    link: '',
    active: false,
    permission: true
  },
  {
    name: 'Integrate',
    link: '',
    active: false,
    permission: true
  },
  {
    name: 'Dashboards',
    link: `${url}boards`,
    active: window.location.pathname === '/boards',
    permission: 'dashboard_permission'
  }
];


export const DiscoverLinks = [
  {
    name: 'Medical Affairs',
    link: `${url}medical`,
    active: 'medical',
    permission: 'loadMedicalDashboard',
    academic: '',
    id: 'ma-dash'
  },
  {
    name: 'Regulatory Updates',
    link: `${url}regulatory`,
    active: 'regulatory',
    permission: 'loadRegulatoryDashboard',
    academic: '',
    id: 'ru-dash'
  },
  {
    name: 'Sentiment Watch',
    link: `${url}sentiment?alreadyLoggedIn=true`,
    active: 'sentiment',
    permission: 'loadSentimentDashboard',
    academic: '',
    id: 'sw-dash'
  },
  {
    name: 'Guideline Watch',
    link: `${url}guidelinewatch`,
    active: 'guidelinewatch',
    permission: 'loadGuidelinesWatchDashboard',
    academic: '',
    id: 'gw-dash'
  },
  {
    name: 'My Dashboard',
    link: `${url}custom`,
    active: 'custom',
    permission: 'loadCustomDashboard',
    academic: '',
    id: 'md-dash'
  },
  {
    name: 'Competitive Landscape',
    link: `${url}competitive`,
    active: 'competitive',
    permission: 'load_competitive_landscape',
    academic: '',
    id: 'cl-dash'
  }
]


export const AppsName = {
  '/guidelinewatch/dashboard': {
    name: 'Guideline Watch',
    id: 'GW-Dashboard',
  },
}
