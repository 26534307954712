import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
const GLOBAL_FILTER_DASHBOARD = 'GLOBAL_FILTER_DASHBOARD'
export const GLOBAL_FILTER_DASHBOARD_SUCCESS = 'GLOBAL_FILTER_DASHBOARD_SUCCESS'
const GLOBAL_FILTER_DASHBOARD_FAILURE = 'GLOBAL_FILTER_DASHBOARD_FAILURE'
const GLOBAL_FILTER_DASHBOARD_CANCEL = 'GLOBAL_FILTER_DASHBOARD_CANCEL'


const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
  errorMessage: ''
}

// Global Filter Dashboard cancel action
export const globalFilterDashboardCancelAction = () => ({
  type: GLOBAL_FILTER_DASHBOARD_CANCEL
})

// Global Filter Dashboard action
export const globalFilterDashboardAction = payload => ({
  type: GLOBAL_FILTER_DASHBOARD,
  payload
})

// Global Filter Dashboard Success action
const globalFilterDashboardSuccess = payload => ({
  type: GLOBAL_FILTER_DASHBOARD_SUCCESS,
  payload
})

// Global Filter Dashboard epic
export const globalFilterDashboardEpic = action$ => action$
  .ofType(GLOBAL_FILTER_DASHBOARD)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/default_filters?${action.payload}`, 'GET'))
    .map(response => globalFilterDashboardSuccess(response))
    .takeUntil(action$.ofType(GLOBAL_FILTER_DASHBOARD_CANCEL))
    .catch(error => Observable.of({
      type: GLOBAL_FILTER_DASHBOARD_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both globalFilterDashboard and logout
export function globalFilterDashboardReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case GLOBAL_FILTER_DASHBOARD: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false,
        errorMessage: ''
      }
    }
    case GLOBAL_FILTER_DASHBOARD_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true,
        errorMessage: ''
      }
    }
    case GLOBAL_FILTER_DASHBOARD_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false,
        errorMessage: action.payload.status === 403 ? "You don't have required permissions, Please contact our adimin" : ''
      }
    }
    default:
      return state
  }
}
