import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { setCookie, getCookie, apiCall, splitToChunks } from '../../utils'
import { ERROR } from '../Status/logic'
import { formApps } from '../utils'

// Constants

const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  loggedIn: getCookie('accessToken')
}

// Login action
export const loginAction = payload => ({
  type: LOGIN,
  payload
})

// Logout action
export const logoutAction = payload => ({
  type: LOGOUT,
  payload
})

// Login Success action
const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
})

// Logout success action
const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload
})

// Login epic
export const loginEpic = action$ => action$
  .ofType(LOGIN)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}${action.payload.url ? action.payload.url : 'v0/auth/callback'}?${action.payload.params}`, 'GET', false))
    .map(response => loginSuccess(response))
    .catch(error => Observable.of({
      type: LOGIN_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Logout epic
export const logoutEpic = action$ => action$
  .ofType(LOGOUT)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}v0/auth/logout?${action.payload}`, 'DELETE'))
    .map(response => logoutSuccess(response))
    .catch(error => Observable.of({
      type: LOGOUT_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both login and logout
export function authReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        loggedIn: false
      }
    }
    case LOGIN_SUCCESS: {
      const userName = `${action.payload.response.profile.id}`
      const bearer = `Bearer ${action.payload.response.accessToken}`
      const chunkLength = Math.ceil(action.payload.response.permissions.length / 80)
      const permissions = splitToChunks(action.payload.response.permissions, chunkLength)
      permissions.forEach((item, i) => {
        setCookie(JSON.stringify(item), `permissions-${i}`)
      })
      setCookie(chunkLength, 'pChunks')
      setCookie(action.payload.response.refreshToken, 'refreshToken')
      setCookie(bearer, 'accessToken')
      setCookie(action.payload.response.profile.first_name, 'name')
      setCookie(action.payload.response.profile.last_name, 'lastName')
      setCookie(userName, 'userName')
      setCookie(action.payload.response.profile.organization || 'individual', 'org')
      setCookie(formApps(action.payload.response.active_apps), 'activeApps')
      if (action.payload.response.is_trial_login) {
        setCookie(true, 'trialUser')
        localStorage.setItem('expiry-trial-period', action.payload.response.expiry_time)
      }
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        loggedIn: true
      }
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        loading: false,
        error: false,
        loggedIn: false
      }
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        loggedIn: false
      }
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        loggedIn: false,
      }
    }
    default:
      return state
  }
}
