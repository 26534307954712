import React from 'react'
import PropTypes from 'prop-types'
import PublicationList from './publication'
import CtList from './ct'
import Grant from './grants'
import RecentEvents from './recentEvents'
import GuideLines from './guidelines'
import SocialMedia from './socialmedia'
import Patent from './patent'
import Thesis from './thesis'
import Congresses from './congresses'
import ThreeDIntegration from './threeDIntegration'
import PublicationWileyList from './publicationWiley'
import Others from './others'
import GuideLinesNotify from './guidelines_notify'
import Societies from './societies'
import Drugs from './drugs'
import DrugList from './druglist'
import RegulatoryUpdates from './regulatoryUpdates'
import Deals from './deals'

const ListType = (props) => {
  switch (props.type) {
    case 'publications':
      return <PublicationList {...props} />;
    case 'clinicaltrials':
      return <CtList {...props} />;
    case 'grants':
      return <Grant {...props} />;
    case 'recent_events':
      return <RecentEvents {...props} />;
    case 'guidelines':
      return <GuideLines {...props} />;
    case 'socialmedia':
      return <SocialMedia {...props} />;
    case 'patent':
      return <Patent {...props} />;
    case 'thesis':
      return <Thesis {...props} />;
    case 'congresses':
      return <Congresses {...props} />;
    case 'Pre Clinical':
      return <ThreeDIntegration {...props} />;
    case 'Drug Discovery':
      return <ThreeDIntegration {...props} />;
    case 'Clinical':
      return <ThreeDIntegration {...props} />;
    case 'publications_wiley':
      return <PublicationWileyList {...props} />;
    case 'guidelines_notify':
      return <GuideLinesNotify {...props} />;
    case 'society':
      return <Societies {...props} />;
    case 'drugs':
      return <Drugs {...props} />
    case 'drug-list':
      return <DrugList {...props} />
    case 'deals':
      return <Deals {...props} />
    case 'regulatory_updates':
      return <RegulatoryUpdates {...props} />
    default:
      return <Others {...props} />;
  }
};

ListType.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}
ListType.defaultProps = {}

export default ListType;
