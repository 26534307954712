import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

export const SORTBY_FETCH = 'SORTBY_FETCH'
const SORTBY_FETCH_SUCCESS = 'SORTBY_FETCH_SUCCESS'
export const SORTBY_FETCH_FAILURE = 'SORTBY_FETCH_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const sortByFetchAction = payload => ({
  type: SORTBY_FETCH,
  payload
})

const sortByFetchSuccess = payload => ({
  type: SORTBY_FETCH_SUCCESS,
  payload
})

export const sortByFetchEpic = action$ => action$
  .ofType(SORTBY_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/sortby?dataset=${action.payload}`, 'GET', true))
    .map(response => sortByFetchSuccess(response))
    .catch(error => Observable.of({
      type: SORTBY_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function sortByFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case SORTBY_FETCH: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case SORTBY_FETCH_SUCCESS: {
      return {
        ...state,
        data: [...action.payload.response.data],
        loading: false,
        error: false,
        flag: true
      }
    }
    case SORTBY_FETCH_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
