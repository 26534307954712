import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { parse, stringify } from 'query-string'
import { getCookie, checkPermission, getRedirectUrl, setAnalytics } from '../../../common/utils';
import { modalAction } from '../../../common/container/Modal/logic'

class AcademicsLanding extends Component {
  componentDidMount() {
    if (getCookie('accessToken')) {
      if (process.env.current === 'app') {
        if (checkPermission('discover_permission')) {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `/search?${stringify({ ...parse(this.props.location.search) })}`
          this.props.history.push(redirectRoute)
        } else if (checkPermission('myworkspace_permission')) {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `/workspace?${stringify({ ...parse(this.props.location.search) })}`
          this.props.history.push(redirectRoute)
        } else if (checkPermission('dashboard_permission')) {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `/boards?${stringify({ ...parse(this.props.location.search) })}`
          this.props.history.push(redirectRoute)
        } else if (checkPermission('marketplace_permission')) {
          const url = getRedirectUrl('app', {}, {}, false)
          window.location.href = `${url}marketplace`
        } else {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `${process.env.redirectRoute}?${stringify({ ...parse(this.props.location.search) })}`
          this.props.history.replace(redirectRoute)
        }
      } else {
        const redirectRoute = parse(this.props.location.search).redirect_url ||
          `${process.env.redirectRoute}?${stringify({ ...parse(this.props.location.search) })}`
        this.props.history.push(redirectRoute)
      }
    } else {
      const remove = document.getElementById('header-container')
      if (remove) {
        remove.style.display = 'none'
      }
    }
  }
  requestInvite = () => {
    setAnalytics('rollingOutInvitesClickedTrigger');
    this.props.modalAction({
      type: 'request_invite',
      open: true,
      dialog: true,
      size: 'medium'
    })
  }
  render() {
    return (
      <div className='academics-landing-special scrollbar' >
        <header style={{ background: '#fff' }} className='header vcenter landing-header'>
          <div className='container vcenter'>
            <div className='header-left vcenter'>
              <div className='logo-wrap vcenter'>
                <a href={`${process.env.mainAppUrl}`} className='logo' ><i className='icon icon-logo-iplexus-academics-color' /></a>
              </div>
              <div className='vcenter'>
                <a href={`${process.env.mainAppUrl}`}><div className='go-back'><i className='back-arrow-left' /> <FormattedMessage id="Back to home" defaultMessage="Back to home" /></div></a>
              </div>
            </div>
            {/* <div className='header-right vcenter'>
              <a href='/'><div className='go-back'><i className='back-arrow-left' /> <FormattedMessage id="Back to home" defaultMessage="Back to home" /></div></a>
            </div> */}
          </div>
        </header>
        <div className="landing-top-blk">
          <div className="bg-img" />
          <div className="container text-center">
            <h1 className="tag-line">
              <FormattedMessage id="Experience the" defaultMessage="Experience the" /> <strong><FormattedMessage id="Life Sciences AI." defaultMessage="Life Sciences AI." /></strong> <FormattedMessage id="Now." defaultMessage="Now." />
            </h1>
            {/* <div className="tag-desc">
              <p>We know you don’t care about every research out there - but ones that matter to you.</p>
              <p>Now its curated, cleaned, analysed at a mouse-click away.</p>
            </div> */}
          </div>
          <div className="btn-wrap text-center">
            <a role='presentation' onClick={() => this.requestInvite()} className="btn btn-primary btn-rounded" >
              <FormattedMessage id="ACCESS NOW" defaultMessage="ACCESS NOW" />
              {process.env.type === 'production' && <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://dc.ads.linkedin.com/collect/?pid=584491&conversionId=588987&fmt=gif" />}
            </a>
          </div>
          <div className="container text-center">
            <div className="tag-desc">
              <div className="txt">
                <p><FormattedMessage id="We are committed to help researchers worldwide" defaultMessage="We are committed to help researchers worldwide in their quest to solve the challenges in Life Sciences.  We have launched our Ontosight Academic Access Program which offers free access of Ontosight Academic version to all researchers and students, affiliated with any university worldwide." /></p>
                {/* <p>With our ACADEMIC version, you can start using <span className="trademark">Ontosight<sup>TM</sup></span> for <strong className="is-bold">free. </strong></p> */}
                <p className="req-invite"><FormattedMessage id="Use your University email for requesting invite." defaultMessage="Use your University email for requesting invite." /></p>
              </div>
            </div>
          </div>
          <div className="stats-count" >
            <div className="container">
              <div className="data-sets-wrap has-space-btwn">
                <div className="data-sets text-center">
                  <div className="count"><FormattedMessage id="35M" defaultMessage="35M" /><span>+</span></div>
                  <div className="name"><FormattedMessage id="Publications" defaultMessage="Publications" /> &amp; <FormattedMessage id="Theses" defaultMessage="Theses" /></div>
                </div>
                <div className="data-sets text-center">
                  <div className="count"><FormattedMessage id="0.5M" defaultMessage="0.5M" /><span>+</span></div>
                  <div className="name"><FormattedMessage id="Clinical Trials" defaultMessage="Clinical Trials" /></div>
                </div>
                <div className="data-sets text-center">
                  <div className="count"><FormattedMessage id="2M" defaultMessage="2M" /><span>+</span></div>
                  <div className="name"><FormattedMessage id="Grants" defaultMessage="Grants" /></div>
                </div>
                <div className="data-sets text-center">
                  <div className="count"><FormattedMessage id="36K" defaultMessage="36K" /><span>+</span></div>
                  <div className="name"><FormattedMessage id="Drug Profiles" defaultMessage="Drug Profiles" /></div>
                </div>
                <div className="data-sets text-center">
                  <div className="count"><FormattedMessage id="40K" defaultMessage="40K" /><span>+</span></div>
                  <div className="name"><FormattedMessage id="Genes Profiles" defaultMessage="Genes Profiles" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-reason-blk">
          <h2 className="title"><FormattedMessage id="Why Ontosight" defaultMessage="Why Ontosight" /><sup>&reg;</sup> <FormattedMessage id="Academic" defaultMessage="Academic" /> ?</h2>
          <div className="container disp-flex">
            <div className="reasons-blk">
              <div className="img">
                <i className="icon landing-academics-search-icon" />
              </div>
              <div className="cont">
                <div>
                  <h3 className="title"><FormattedMessage id="Search using Life Science ontology" defaultMessage="Search using Life Science ontology" /></h3>
                  <div className="desc"><FormattedMessage id="Our ontology understands life science concepts and terms." defaultMessage="Our ontology understands life science concepts and terms. It is able to understand that acronyms are related to various concepts and can distinguish between two concepts that share the same acronym such as EGFR." /></div>
                </div>
              </div>
            </div>
            <div className="reasons-blk">
              <div className="img">
                <i className="icon landing-academics-connection-icon" />
              </div>
              <div className="cont">
                <div>
                  <h3 className="title"><FormattedMessage id="Single platform" defaultMessage="Single platform" /></h3>
                  <div className="desc"><FormattedMessage id="Ontosight" defaultMessage="Ontosight" /><sup>&reg;</sup> <FormattedMessage id="Academic consolidates your search by connecting data from thousands* of life science databases. Ontosight" defaultMessage="Academic consolidates your search by connecting data from thousands* of life science databases. Ontosight" /><sup>&reg;</sup> <FormattedMessage id="Academic actively crawls and aggregates over 97% of the life science public domain." defaultMessage="Academic actively crawls and aggregates over 97% of the life science public domain." /></div>
                </div>
              </div>
            </div>
            <div className="reasons-blk">
              <div className="img">
                <i className="icon landing-academics-security-icon" />
              </div>
              <div className="cont">
                <div>
                  <h3 className="title"><FormattedMessage id="Updated in real-time" defaultMessage="Updated in real-time" /></h3>
                  <div className="desc"><FormattedMessage id="New data is assimilated into our data ocean immediately after publication. Set alerts for specific search clusters to stay on top of your unique research area." defaultMessage="New data is assimilated into our data ocean immediately after publication. Set alerts for specific search clusters to stay on top of your unique research area." /></div>
                </div>
              </div>
            </div>
            <div className="reasons-blk">
              <div className="img">
                <i className="icon landing-academics-it-icon" />
              </div>
              <div className="cont">
                <div>
                  <h3 className="title"><FormattedMessage id="Find the cutting-edge" defaultMessage="Find the cutting-edge" /></h3>
                  <div className="desc"><FormattedMessage id="Ontosight" defaultMessage="Ontosight" /><sup>&reg;</sup> <FormattedMessage id="Academic can help you navigate fast-moving research frontiers. Ontosight" defaultMessage="Academic can help you navigate fast-moving research frontiers. Ontosight" /><sup>&reg;</sup> <FormattedMessage id="Academic encompasses a massive volume of life science congresses and theses, ensuring that you have access to the most nascent research being done in your field." defaultMessage="Academic encompasses a massive volume of life science congresses and theses, ensuring that you have access to the most nascent research being done in your field." /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pricing-blk">
          <h3 className="title text-center"> Pricing Plans</h3>
          <div className="container">
            <div className="pricing-table">
              <div className="pricing-table-header">
                <div className="table-row has-space-btwn">
                  <div className="col">Features</div>
                  <div className="col">Academic</div>
                  <div className="col">Premium</div>
                </div>
              </div>
              <div className="pricing-table-body">
                <div className="table-row has-space-btwn">
                  <div className="col">Concept Based Search</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Innovative search crumbs for getting the right query</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Publications data</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Clinical Trials data</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Congresses data</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Theses data</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Patents data</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Grants data</div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">News & Press Releases data</div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Treatment Guidelines data</div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Social Media data</div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col"><strong>Medical Affairs</strong> dashboard for focused analysis</div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col"><strong>Regulatory Updates</strong> dashboard for focused analysis</div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col"><strong>Sentiment Analysis</strong> dashboard for focused analysis</div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Get detailed insights around <strong>Intervention</strong></div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Get detailed insights around <strong>Gene</strong></div>
                  <div className="col"><span className="tick" /></div>
                  <div className="col"><span className="tick" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col">Get detailed insights around <strong>Researcher</strong></div>
                  <div className="col"><span className="tick na" /></div>
                  <div className="col"><span className="tick premium" /></div>
                </div>
                <div className="table-row has-space-btwn">
                  <div className="col" />
                  <div className="col">
                    <div className="btn-wrap text-center">
                      <a className="btn btn-primary btn-rounded" ng-click="landing.open(1)">REQUEST ACCESS </a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="btn-wrap text-center">
                      <a rel="noopener noreferrer" className="btn btn-primary primary btn-rounded" href="https://www.innoplexus.com/index.php/buy-now/" target="_blank">BUY PREMIUM </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-academics">
          <div className="footer-top">
            <div className="container">
              <div className="title-wrap text-center">
                <h3 className="title"> <FormattedMessage id="A PRODUCT BY" defaultMessage="A PRODUCT BY" /> <img className='innoplexus-footer-academics-logo' src="https://www.innoplexus.com/wp-content/uploads/2017/08/innoplexus_logo_white@2x.png" alt="logo" /></h3>
              </div>
              <div className="desc">
                <FormattedMessage id="Innoplexus is structuring the world's information" defaultMessage="Innoplexus is structuring the world's information to make AI easier. Innoplexus offers Data as a Service (DaaS) and Continuous Analytics as a Service (CAaS) products, leveraging Artificial Intelligence, proprietary algorithms and patent pending technologies to help global Life Sciences organisations with access to relevant data, intelligence & intuitive insights, across pre-clinical, clinical, regulatory and commercial stages of a drug." />
              </div>
              <div className='desc' >
                <FormattedMessage id="We automate the collection and curation of data using technologies such as natural language processing" defaultMessage="We automate the collection and curation of data using technologies such as natural language processing, network analysis, ontologies, computer vision and entity normalisation. Our clients are Fortune 500 companies including some of the world’s largest pharmaceutical companies." />
              </div>
              <div className="btn-wrap clearfix">
                <a rel="noopener noreferrer" href="http://www.innoplexus.com" target="_blank" className="pull-right"> <FormattedMessage id="VISIT WEBSITE" defaultMessage="VISIT WEBSITE" /> <i className="chevron right" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
AcademicsLanding.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
}
export default withRouter(connect(null, { modalAction })(AcademicsLanding))
