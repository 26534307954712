import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown } from '../../../common/components'

class YearRange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startYear: props.startYear || props.defaultStartYear,
      endYear: props.endYear || props.defaultEndYear
    }
    this.renderStartYear = this.renderStartYear.bind(this)
    this.renderEndYear = this.renderEndYear.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.startYear || !nextProps.endYear || nextProps.startYear !== this.state.startYear || nextProps.endYear !== this.state.endYear) {
      this.setState({
        startYear: nextProps.startYear || this.props.defaultStartYear,
        endYear: nextProps.endYear || this.props.defaultEndYear
      })
    }
  }
  onChange(value, type) {
    if (type === 'start-year') {
      this.setState({
        startYear: value || this.props.defaultStartYear,
        endYear: value ? this.state.endYear >= value ? this.state.endYear : value : this.state.endYear
      }, () => this.props.onChange([this.state.startYear, this.state.endYear]))
    } else {
      this.setState({
        endYear: value || this.props.defaultEndYear
      }, () => this.props.onChange([this.state.startYear, this.state.endYear]))
    }
  }
  renderStartYear() {
    const { intl } = this.props
    const yearList = Array.from(new Array((this.props.defaultEndYear - this.props.defaultStartYear) + 1), (val, index) => Number.parseInt(index + this.props.defaultStartYear, 10))
    return (
      <div style={{ padding: 5 }} >
        <div>
          <FormattedMessage id="From" defaultMessage='From' />
        </div>
        <div className='start-year'>
          <Dropdown minLength={1} title={intl.formatMessage({ id: 'Start Year', defaultMessage: 'Start Year' })} active={[this.state.startYear]} options={yearList} onChange={value => this.onChange(parseInt(value[0], 10), 'start-year')} />
        </div>
      </div>
    )
  }
  renderEndYear() {
    const { intl } = this.props
    const yearList = Array.from(new Array((this.props.defaultEndYear - this.state.startYear) + 1), (val, index) => Number.parseInt(index + this.state.startYear, 10))
    return (
      <div style={{ padding: 5 }} >
        <div>
          <FormattedMessage id="To" defaultMessage='To' />
        </div>
        <div className='end-year'>
          <Dropdown minLength={1} title={intl.formatMessage({ id: 'End Year', defaultMessage: 'End Year' })} active={[this.state.endYear]} options={yearList} onChange={value => this.onChange(parseInt(value[0], 10), 'end-year')} />
        </div>
      </div>
    )
  }
  render() {
    return (
      <Fragment>
        {this.renderStartYear()}
        {this.renderEndYear()}
      </Fragment>
    )
  }
}

YearRange.propTypes = {
  startYear: PropTypes.number,
  endYear: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  defaultStartYear: PropTypes.number,
  defaultEndYear: PropTypes.number,
  intl: PropTypes.object.isRequired
}

YearRange.defaultProps = {
  startYear: 1960,
  endYear: new Date().getFullYear(),
  defaultStartYear: 1960,
  defaultEndYear: new Date().getFullYear()
}

export default injectIntl(YearRange)
