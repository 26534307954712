import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';

const ClickableDOI = (props) => {
  const { DOI } = props.data
  const renderDOI = () => (
    <Fragment>
      {
        DOI && DOI !== 'not found' ?
          <Fragment>
            <div className='doi'>
              <div className='sub-title'><FormattedMessage id="DOI" defaultMessage="DOI" /></div>
              {props.data['DOI Link'] ?
                <a className='sub-text' rel="noopener noreferrer" target='_blank' href={props.data['DOI Link']}>{props.data.DOI} </a> :
                <div className='sub-text'> {props.data.DOI} </div>
              }
            </div>
          </Fragment> : null
      }
    </Fragment >
  )
  return (
    <Fragment>
      {renderDOI()}
    </Fragment>
  )
}

ClickableDOI.propTypes = {
  data: PropTypes.object.isRequired
}

ClickableDOI.defaultProps = {
}

export default ClickableDOI
