import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'
import { apiCallFetch, checkPermission } from '../../utils'
import { createBibliographySuccessAction, deleteBibliographySuccessAction } from './logic'
import { statusError } from '../Status/logic'
import { modalAction } from '../Modal/logic'

class Bibliography extends Component {
  onToggle(e, flag) {
    e.stopPropagation()
    if (flag) {
      this.props.modalAction({
        type: 'create_bibliography',
        open: true,
        dialog: true,
        size: 'small',
        data: {
          id: this.props.id,
          dataset: this.props.type,
          group: this.props.group
        }
      })
    } else {
      this.props.modalAction({
        type: 'delete_bibliography',
        open: true,
        dialog: true,
        size: 'small',
        data: {
          id: this.props.id,
          dataset: this.props.type,
          group: this.props.group
        }
      })
    }
  }
  deleteBibliography(query) {
    const url = `${process.env.faceliftApiUrl}v0/bibliography/delete/?${stringify(query)}`;
    const params = apiCallFetch('DELETE_BIBLOGRAPHY', true)

    fetch(url, params)
      .then(res => res.json())
      .catch(e => this.props.statusError(e))
      .then(res => this.props.deleteBibliographySuccessAction({ id: res.doc_id, status: false }))
  }
  createBibliography(query) {
    const url = `${process.env.faceliftApiUrl}v0/bibliography/createBibliography`;
    const params = apiCallFetch('POST', true, query)

    fetch(url, params)
      .then(res => res.json())
      .catch(e => this.props.statusError(e))
      .then(res => this.props.createBibliographySuccessAction({ id: res.doc_id, status: true }))
  }
  render() {
    const { intl } = this.props
    if (checkPermission('my_bibliography_permission') && checkPermission(`${this.props.type}_bibliography_permission`)) {
      return (
        <div className='icons is-clickable hand' >
          <i title={intl.formatMessage({ id: 'Bibliography', defaultMessage: 'Bibliography' })} role='presentation' data-status={this.props.flag} onClick={e => this.onToggle(e, !this.props.flag)} className={`${this.props.flag ? 'bibliograpy-icon-active' : 'bibliograpy-icon'}`} />
        </div>
      )
    }
    return null
  }
}

Bibliography.propTypes = {
  flag: PropTypes.bool,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  createBibliographySuccessAction: PropTypes.func.isRequired,
  deleteBibliographySuccessAction: PropTypes.func.isRequired,
  statusError: PropTypes.func.isRequired,
  modalAction: PropTypes.func.isRequired,
  group: PropTypes.string,
  intl: PropTypes.object.isRequired
}

Bibliography.defaultProps = {
  group: 'Published',
  flag: false
}

export default connect(null, {
  createBibliographySuccessAction, deleteBibliographySuccessAction, statusError, modalAction
})(injectIntl(Bibliography))
