import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
const VIEW_SEARCH_AUTO_COMPLETE = 'VIEW_SEARCH_AUTO_COMPLETE'
export const VIEW_SEARCH_AUTO_COMPLETE_SUCCESS = 'VIEW_SEARCH_AUTO_COMPLETE_SUCCESS'
const VIEW_SEARCH_AUTO_COMPLETE_FAILURE = 'VIEW_SEARCH_AUTO_COMPLETE_FAILURE'
const VIEW_SEARCH_AUTO_COMPLETE_CANCEL = 'VIEW_SEARCH_AUTO_COMPLETE_CANCEL'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const viewSearchAutoCompleteCancelAction = () => ({
  type: VIEW_SEARCH_AUTO_COMPLETE_CANCEL
})

export const viewSearchAutoCompleteAction = payload => ({
  type: VIEW_SEARCH_AUTO_COMPLETE,
  payload
})

const viewSearchAutoCompleteSuccess = payload => ({
  type: VIEW_SEARCH_AUTO_COMPLETE_SUCCESS,
  payload
})

export const viewSearchAutoCompleteEpic = action$ => action$
  .ofType(VIEW_SEARCH_AUTO_COMPLETE)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.apiUrl}search/autocomplete?query=${action.payload}&size=10`, 'GET'))
    .map(response => viewSearchAutoCompleteSuccess(response))
    .takeUntil(action$.ofType(VIEW_SEARCH_AUTO_COMPLETE_CANCEL))
    .catch(error => Observable.of({
      type: VIEW_SEARCH_AUTO_COMPLETE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function viewSearchAutoCompleteReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case VIEW_SEARCH_AUTO_COMPLETE: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case VIEW_SEARCH_AUTO_COMPLETE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case VIEW_SEARCH_AUTO_COMPLETE_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}

