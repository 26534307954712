import React from 'react'
import PropTypes from 'prop-types'

const LegendItem = props => (
  <div
    className={`legends-item ${props.className} ${props.clickable ** props.allowClick ? '' : 'disabled'}`}
    style={{
    cursor: props.notAllowed && props.clickable ? 'not-allowed' : props.allowClick ? 'pointer' : 'default'
  }}
  >
    <div style={{ backgroundColor: props.color, borderRadius: props.borderRadius ? props.borderRadius : '10px' }} className='legend-bullet' />
    {props.allowClick ?
      <a
        onClick={() => props.clicked(props.category)}
        className='legend-text'
        role="presentation"
        style={{
        cursor: props.notAllowed && props.clickable ? 'not-allowed' : 'pointer'
      }}
      >{props.category}
      </a> : <span className='legend-text'> { props.category } </span>}
  </div>
)

LegendItem.propTypes = {
  color: PropTypes.string,
  borderRadius: PropTypes.number,
  category: PropTypes.string,
  className: PropTypes.string,
  clicked: PropTypes.func,
  clickable: PropTypes.bool,
  allowClick: PropTypes.bool,
  notAllowed: PropTypes.bool,

}

LegendItem.defaultProps = {
  color: '',
  borderRadius: 10,
  category: '',
  className: '',
  clickable: false,
  clicked: () => null,
  allowClick: false,
  notAllowed: false
}

export default LegendItem
