import React, { Component } from 'react'
import * as d3 from 'd3'
import PropTypes from 'prop-types'
import { NoDataFound } from '../'

class LineGraph extends Component {
  static getTransformToElement (svg, elem) {
    return elem.getScreenCTM().inverse().multiply(svg.getScreenCTM())
  }
  constructor (props) {
    super(props)
    this.state = {
      // timestamp: null,
      index: null,
      position: -1,
      hovered: false
      // data: this.props.data.map(d => {
      //   const time = new Date(d.category * 1000).toUTCString()
      //   const obj = JSON.parse(JSON.stringify(d))
      //   obj.time = time
      //   return obj
      // })
    }
    this.marginLeft = this.props.show ? 40 : 20
    this.marginRight = 20
    this.marginTop = 25
    this.marginBottom = 30
    this.width = this.props.width - this.marginLeft - this.marginRight
    this.height = this.props.height - this.marginTop - this.marginBottom
  }
  componentWillMount () {
    this.xScale = d3.scaleBand()
    this.yScale = d3.scaleLinear()
    this.xScale.domain(this.props.data.map(d => d.year))
    this.xScale.range([0, this.width])
    let min = 10000000
    this.props.data.map((d) => {
      if (min > d.value) {
        min = d.value
      }
      return 0
    })
    const l = min.toString().length - 1
    min = Number(min)
    min -= 10 ** l
    this.yScale.domain([min,
      d3.max(this.props.data.map(d => d.value))])
    this.yScale.range([this.height, 0])
    if (this.props.show) {
      const max = d3.max(this.props.data.map(d => d.value))
      let maxPos = 0
      this.props.data.forEach((element, i) => {
        if (element.value === max) {
          maxPos = i
        }
      });
      this.setState({
        position: maxPos,
        index: maxPos
      })
    }
  }
  componentDidMount () {
    this.renderAxis()
    const xnode = '#line-x-axis'
    if (!this.props.show) {
      d3.select(xnode)
        .selectAll('.tick text')
        .text((d, i) => {
          if (i === 0 || i === this.props.data.length - 1) {
            return d
          }
          return ''
        })
    } else {
      d3.select('.svg-tooltip')
        .style('display', 'block')
        .attr('transform', `translate(${this.xScale(this.props.data[this.state.index].year) + (this.xScale.bandwidth() / 2)}, ${this.yScale(this.props.data[this.state.index].value)})`)
      // .attr('transform', `translate(${this.x(this.props.data[index].category * 1000) + (this.x.bandwidth() / 2)}, ${this.y(this.props.data[index].value)})`)
    }
    this.showDiv()
  }
  componentWillReceiveProps () {
    this.showDiv()
  }
  showDiv() {
    const p = document.getElementById(`Divtooltip${this.props.index}`)
    if (p && this.state.position > -1 && this.state.position < this.props.data.length) {
      const width = Number(p.clientWidth) === 0 ? 14 : Number(p.clientWidth) / 2;
      p.style.left = `${this.marginLeft + this.xScale(this.props.data[this.state.position].year) + (-width) + (this.xScale.bandwidth() / 2)}px`
      p.style['z-index'] = 1050
      const height = Number(p.clientHeight) === 0 ? 24 : Number(p.clientHeight)
      p.style.top = `${this.marginTop + this.yScale(this.props.data[this.state.position].value) + (-height) + (-15)}px`
      p.style.display = 'block'
    }
  }
  renderAxis () {
    const xnode = '#line-x-axis'
    const xaxis = d3.axisBottom(this.xScale).tickSize(0)
    d3.select(xnode).call(xaxis)
    if (this.props.show) {
      const ynode = '#line-y-axis'
      const yaxis = d3.axisLeft(this.yScale).tickSize(0).ticks(5)
      d3.select(ynode).call(yaxis)
    }
  }
  renderDiv() {
    return (
      <div className='container1'>
        <div className='row1' id={`row1_${this.props.id}`}>
          {this.state.position > -1 && this.state.position < this.props.data.length ? this.props.data[this.state.position].value : ''}
        </div>
        {/* <div className='row'>
          ClinicalTrials: {this.state.position > -1 && this.state.position < this.props.data[1].length ? this.props.data[1][this.state.position].value : ''}
        </div> */}
      </div>
    )
  }
  renderOverlay () {
    const q = this.props.data.length
    return (
      <rect
        width={q === 1 ? 20 : this.width - (this.xScale.bandwidth() / 2)}
        height={q === 1 ? 20 : this.height}
        x={q === 1 ? this.xScale(this.props.data[0].year) + (this.xScale.bandwidth() / 2) : 0}
        y={q === 1 ? this.yScale(this.props.data[0].value) : 0}
        className='handicon'
        fill='none'
        style={{ pointerEvents: 'all' }}
        onMouseMove={(event) => {
          const svg = document.getElementById(`svg-line_${this.props.id}`)
          const g = document.getElementById(`group-${this.props.id}`)
          const p = svg.createSVGPoint()
          p.x = event.clientX
          p.y = event.clientY
          const transformSvg = LineGraph.getTransformToElement(svg, g)
          const trans = transformSvg.multiply(svg.getScreenCTM().inverse())
          const svgp = p.matrixTransform(trans)
          if (this.props.data.length > 1) {
            const eachBand = this.xScale.step()
            const index = Math.round((svgp.x / eachBand))
            this.setState({
              index,
              position: index,
              hovered: true
            }, () => {
              this.showDiv()
            })
            if (index > -1 && index < this.props.data.length) {
              d3.select('.svg-tooltip')
                .style('display', 'block')
                .attr('transform', `translate(${this.xScale(this.props.data[index].year) + (this.xScale.bandwidth() / 2)}, ${this.yScale(this.props.data[index].value)})`)
            }
          } else {
            // const eachBand = this.x.step();
            // const index = Math.round((svgp.x / eachBand))
            const index = 0
            this.setState({
              index
            }, () => {
              this.showDiv()
            })
            // if (index > -1) {
              d3.select('.svg-tooltip')
                .style('display', 'block')
                // .attr('transform', `translate(${this.x(this.props.data[index].category * 1000) + (this.x.bandwidth() / 2)}, ${this.y(this.props.data[index].value)})`)
            // }
          }
        }}
        onMouseLeave={() => {
          if (this.state.hovered) {
            const p = document.getElementById(`Divtooltip${this.props.index}`)
            if (p) {
              p.style.display = 'none'
            }
            this.setState({
              position: -1
            })
            d3.select('.svg-tooltip')
              .style('display', 'none')
          }
      }}
      />
    )
  }
  renderTooltip () {
    // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    if (this.state.index !== null && this.state.index > -1 && this.state.index < this.props.data.length) {
      // const l = this.props.data[this.state.index].value.toString().length
      return (
        <g className='svg-tooltip' transform={`translate(${this.xScale(this.props.data[0].year)}, ${this.yScale(this.props.data[0].value)})`} style={{ display: 'none' }}>
          <circle
            r={7}
            fill={this.props.colorFill}
            opacity='1'
          />
          {/* <circle
            r={4}
            stroke='white'
            strokeWidth='0.2px'
            fill='#21538c'
          /> */}
          {/* <rect
            y={-30}
            x={0}
            width={l * 10}
            height={20}
            fill='#fff'
          /> */}
          <text>
            {this.state.index > 0 && this.state.index < this.props.data.length - 1 && !this.props.show ?
              <tspan
                className='tick1'
                y={this.state.index > 0 ? this.height + (-this.yScale(this.props.data[this.state.index].value)) + 25 : ''}
                x={-15}
              >
                {this.state.index > -1 ? this.props.data[this.state.index].year : ''}
              </tspan> :
            null}
          </text>
          {this.state.index > -1
            ? (
              <path
                d={`M0,0 L0,${this.height + (-this.yScale(this.props.data[this.state.index].value))}`}
                stroke='#fff'
                fill='none'
                strokeWidth='1'
                strokeDasharray='3,3'
              />
            ) : null}
          {/* <text
            className='graph-font-family'
          >
            <tspan
              x={35}
              y={-5}
            >
              { this.state.index > -1 ? `${new Date(1000 * this.props.data[this.state.index].category).getDate()}, ${months[new Date(1000 * this.props.data[this.state.index].category).getMonth()]}, ${new Date(this.props.data[this.state.index].category * 1000).getFullYear()}` : 'NA' }
            </tspan>
            <tspan
              x={35}
              y={10}
            >
              { this.state.index > -1 ? (this.props.data[this.state.index].value).toFixed(2) : 'NA' }
            </tspan>
          </text> */}
        </g>
      )
    }
    return null
  }
  renderLine () {
    const valueline = d3.line()
      .x(d => this.xScale(d.year) + (this.xScale.bandwidth() / 2))
      .y(d => this.yScale(d.value))
    const area = d3.area()
      .x(d => this.xScale(d.year) + (this.xScale.bandwidth() / 2))
      .y0(this.height)
      .y1(d => this.yScale(d.value))
    return (
      <g>
        <path
          key={1}
          d={area(this.props.data)}
          fill={this.props.colorFill}
          className='path'
          fillOpacity={0.1}
        />
        <path
          d={valueline(this.props.data)}
          fill='none'
          stroke={this.props.colorFill}
          strokeWidth='2.5px'
          className='path'
        />
      </g>

    )
  }
  renderLabelX () {
    return (
      <text
        transform={`translate(${this.width / 2}, ${this.height + (this.marginBottom / 2)})`}
        textAnchor='middle'
        className='graph-font-family capitalize-me'
      >
        {this.props.labelX}
      </text>
    )
  }
  renderLabelY () {
    return (
      <text
        transform={`translate(${this.marginLeft / 3}, ${(this.height / 1.9) + this.marginTop})rotate(-90)`}
        textAnchor='middle'
        className='graph-font-family capitalize-me'
      >
        {this.props.labelY}
      </text>
    )
  }
  render () {
    const style = {
      height: this.props.height,
      width: this.props.width
    }
    if (this.props.data.length === 0) {
      return <NoDataFound style={style} {...(this.props.message ? { message: this.props.message } : {})} />
    }
    return (
      <div className='wrap'>
        <div className='Divtooltip' id={`Divtooltip${this.props.index}`} style={{ display: 'none' }} >
          {this.renderDiv()}
        </div>
        <svg width={this.props.width} height={this.props.height} id={`svg-line_${this.props.id}`}>
          <g transform={`translate(${this.marginLeft}, ${this.marginTop})`} id={`group-${this.props.id}`}>
            <g ref={this.xaxis} id='line-x-axis' className='x axis' transform={`translate(0, ${this.height})`} />
            <g ref={this.yaxis} id='line-y-axis' className='y axis' />
            {this.renderLine()}
            { this.renderTooltip() }
            { this.renderOverlay() }
          </g>
          { this.props.labelX ? this.renderLabelX() : null}
          { this.props.labelY ? this.renderLabelY() : null}
        </svg>
        {/* {this.state.position > -1 && this.state.mounted ? this.showDiv() : null} */}
      </div>
    )
  }
}
LineGraph.propTypes = {
  //   addToSearch: PropTypes.func.isRequired,
  //   viewDetails: PropTypes.func.isRequired,
  labelX: PropTypes.string.isRequired,
  labelY: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  show: PropTypes.bool,
  index: PropTypes.number,
  colorFill: PropTypes.string,
  message: PropTypes.string,
  id: PropTypes.string,
//   color: PropTypes.array.isRequired
}

LineGraph.defaultProps = {
  show: false,
  index: 0,
  message: null,
  colorFill: '#fff',
  id: 'there'
}
export default LineGraph
