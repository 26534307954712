import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LineGraph } from '../../../common/components'

class LineGraphFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }
  componentWillMount() {
    this.props.data.forEach(d => (Number(d.year) <= (new Date()).getFullYear() && Number(d.year) > 2007 ? this.state.data.push(d) : 0));
  }
  render() {
    return (
      <div>
        <LineGraph data={this.state.data} labelX='' labelY='' height={this.props.height} width={this.props.width} index={1} message='No trends found' />
      </div>
    )
  }
}
LineGraphFilter.propTypes = {
//   trendsAction: PropTypes.func.isRequired,
//   trends: PropTypes.object.isRequired,
//   location: PropTypes.shape({
//     search: PropTypes.string
//   }),
  data: PropTypes.array.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
LineGraphFilter.defaultProps = {
//   location: {
//     search: ''
//   },
  height: 130,
  width: 270
}
export default LineGraphFilter
