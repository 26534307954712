import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
const HOOK_FETCH = 'HOOK_FETCH'
export const HOOK_FETCH_SUCCESS = 'HOOK_FETCH_SUCCESS'
const HOOK_FETCH_FAILURE = 'HOOK_FETCH_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

// Auto Complete action
export const hookFetchAction = payload => ({
  type: HOOK_FETCH,
  payload
})

// Auto Complete Success action
const hookFetchSuccess = payload => ({
  type: HOOK_FETCH_SUCCESS,
  payload
})

// Auto Complete epic
export const hookFetchEpic = action$ => action$
  .ofType(HOOK_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/hooks?`, 'POST', true, action.payload))
    .map(response => hookFetchSuccess(response))
    .catch(error => Observable.of({
      type: HOOK_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both hookFetch and logout
export function hookFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case HOOK_FETCH: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case HOOK_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case HOOK_FETCH_FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
