const INITIAL_STATE = {
  lang: 'en',
  dataLang: 'en'
};

export const LangSet = (lang) => {
  localStorage.lang = lang;
  return {
    type: 'SET_LANG',
    lang,
  }
};

export const setDataLang = dataLang => ({
  type: 'SET_DATA_LANG',
  dataLang
});

export const langReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'SET_LANG':
      return {
        ...state,
        lang: action.lang
      };
    case 'SET_DATA_LANG':
      return {
        ...state,
        dataLang: action.dataLang
      };
    default:
      return state;
  }
};
