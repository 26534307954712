import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall, j, guidGenerator } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

const SUMMARY_GRAPH_METADATA = 'SUMMARY_GRAPH_METADATA'
const SUMMARY_GRAPH_METADATA_SUCCESS = 'SUMMARY_GRAPH_METADATA_SUCCESS'
const SUMMARY_GRAPH_METADATA_FAILURE = 'SUMMARY_GRAPH_METADATA_FAILURE'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false
}

export const summaryFetchAction = payload => ({
  type: SUMMARY_GRAPH_METADATA,
  payload
})

const summaryFetchSuccess = payload => ({
  type: SUMMARY_GRAPH_METADATA_SUCCESS,
  payload
})

export const summaryFetchEpic = action$ => action$
  .ofType(SUMMARY_GRAPH_METADATA)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/summaryDashboard/getGraphMetaData?${action.payload}`, 'GET', true))
    .map(response => summaryFetchSuccess(response))
    .catch(error => Observable.of({
      type: SUMMARY_GRAPH_METADATA_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function summaryFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case SUMMARY_GRAPH_METADATA: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case SUMMARY_GRAPH_METADATA_SUCCESS: {
      const data = j(action.payload.response.data).map(item => ({ ...item, id: guidGenerator() }))
      return {
        ...state,
        data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SUMMARY_GRAPH_METADATA_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
