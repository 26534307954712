import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import { ListIcons, AssetTag } from '..'
import { readableDate, setAnalytics } from '../../utils'

const RegulatoryUpdates = props => (
  <Fragment>
    {props.flag && (
      <div className="card-actions">
        <Bookmark
          flag={props.data.bookmark}
          type={props.type}
          id={props.data.doc_id}
          group="Published"
        />
      </div>
    )}
    <div className="card-details">
      <div className="card-meta">
        <AssetTag asset={props.data.type} />
        {props.data['Data Source'] && (
          <div className="card-meta-title text-elips">
            {props.data['Data Source']}
          </div>
        )}
        <div className="date">{readableDate(props.data.Date)}</div>
      </div>
      <h3 className="card-title">{ReactHtmlParser(props.data.Title)}</h3>
    </div>
    {props.data['Source Link'] &&
      !props.overlay && (
        <a
          title={props.intl.formatMessage({ id: 'Source link', defaultMessage: 'Source link' })}
          onClick={(e) => {
            setAnalytics(
              'sourceLinkTrigger',
              'sourceLink',
              props.data['Source Link'].match(/^https?:/)
                ? props.data['Source Link']
                : `http://${props.data['Source Link']}`
            )
            e.stopPropagation()
          }}
          rel="noopener noreferrer"
          className="web-link"
          target="_blank"
          href={props.data['Source Link']}
        />
      )}
    {props.overlay && (
      <ListIcons
        {...props}
        data={props.data}
        likeParams={props.likeParams}
        takeFeedback={props.takeFeedback}
      />
    )}
  </Fragment>
)

RegulatoryUpdates.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  flag: PropTypes.bool,
  showMore: PropTypes.bool,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  overlay: PropTypes.bool,
  intl: PropTypes.object.isRequired
}

RegulatoryUpdates.defaultProps = {
  flag: true,
  showMore: false,
  likeParams: {},
  takeFeedback: true,
  overlay: true
}

export default injectIntl(RegulatoryUpdates)
