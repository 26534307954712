import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import NotificationSystem from 'react-notification-system'
import { parse, stringify } from 'query-string'
import { getCookie, removeCookies, getRedirectUrl, PATHS, setAnalytics, checkPermission } from '../../utils'
import { statusCancel, customMessage } from './logic'
// import { notificationCountAction } from '../Notification/logic'
import { modalCloseAction } from '../Modal/logic'
import { ChatBox, OutsideClick, ErrorBoundary } from '../../components'
import { setDataLang, LangSet } from '../../lang/logic'

// const setFavicons = () => {
//   const target = checkPermission('ontosight_academics_user') && getCookie('accessToken') ? 'academics-favicon' : 'favicon'
//   if (document) {
//     document.querySelector('link[rel=icon][sizes="16x16"]').href = `/images/${target}/favicon-16x16.png?${new Date().getTime()}`
//     document.querySelector('link[rel=icon][sizes="32x32"]').href = `/images/${target}/favicon-32x32.png?${new Date().getTime()}`
//     document.querySelector('link[rel="shortcut icon"]').href = `/images/${target}/favicon.ico?${new Date().getTime()}`
//     document.querySelector('link[rel=apple-touch-icon][sizes="180x180"]').href = `/images/${target}/apple-touch-icon.png?${new Date().getTime()}`
//     document.querySelector('link[rel=manifest]').href = `/images/${target}/site.webmanifest?${new Date().getTime()}`
//   }
// }

class Status extends Component {
  componentDidMount() {
    if (typeof (getCookie('accessToken')) === 'undefined' && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      this.props.history.replace(process.env.mainRoute)
    }
    if (getCookie('accessToken')) {
      // document.body.style.overflow = 'hidden'
      document.body.className = ''
      setAnalytics('checkLoginTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
      // setFavicons()
    }
    this.checkLocalization(this.props.lang)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.status.type !== this.props.status.type && nextProps.status.type !== '') {
      const { intl } = nextProps
      this.showNotification(nextProps.status, intl)
      this.activityFlow(nextProps.status.type, nextProps)
      this.props.statusCancel()
    }
    if (typeof (getCookie('accessToken')) === 'undefined' && nextProps.location.pathname !== process.env.mainRoute) {
      let redirectRoute = process.env.mainRoute
      if (nextProps.status.type === '401') {
        const query = stringify({
          redirect_url: this.props.location.pathname + this.props.location.search
        })
        redirectRoute += `?${query}`
        this.props.history.replace(redirectRoute)
      } else if (process.env.standalone) {
        this.props.history.replace(redirectRoute)
      } else {
        window.location.href = getRedirectUrl('app', {}, {}, false)
      }
    }
    if (getCookie('accessToken')) {
      // document.body.style.overflow = 'hidden'
      document.body.className = ''
    }
  }
  checkLocalization (lang) {
    if (!checkPermission('language_localization') && lang !== 'en' && getCookie('accessToken')) {
      setTimeout(() => {
        this.props.customMessage({
          message: 'You do not have Localization permission, you will still be able to browse platform in English. Please contact for permission.',
          status: 'info',
          title: 'Info'
        })
      }, 300)
      const langCode = 'en';
      this.props.LangSet(langCode);
      this.props.setDataLang(langCode);
    }
  }
  activityFlow(value, nextProps) {
    switch (value) {
      case '401': {
        setAnalytics('sessionExpiredTrigger', 'sessionExpired', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        removeCookies();
        window.location.href = `${process.env.mainAppUrl}/logout`
        break
      }
      case '403': {
        break
      }
      case 'login': {
        setAnalytics('userIdTrigger', 'userId', `${getCookie('userName')}@@${checkPermission('ontosight_academics_user') ? `academics${getCookie('org') ? `##${getCookie('org')}` : ''}` : getCookie('org') || 'individual'}`)
        // setFavicons()
        if (getCookie('accessToken') && (checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user'))) {
          // this.props.notificationCountAction()
        }
        this.props.modalCloseAction()
        this.checkLocalization(nextProps.lang)
        const {
          alreadyLoggedIn, hashCode, code, ...others
        } = parse(this.props.location.search)
        if (process.env.current === 'app') {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
              `/boards?${stringify({ ...others })}`
          this.props.history.replace(redirectRoute)
        } else {
          const redirectRoute = parse(this.props.location.search).redirect_url ||
            `${process.env.redirectRoute}?${stringify({ ...others })}`
          this.props.history.replace(redirectRoute)
        }
        break
      }
      case 'trial-expiry': {
        setTimeout(() => {
          localStorage.clear();
          removeCookies();
        }, 500);
        window.location.href = `${process.env.mainAppUrl}/logout`;
        // setFavicons()
        break
      }
      case 'logout': {
        setTimeout(() => {
          localStorage.clear()
          removeCookies()
        }, 500)
        window.location.href = `${process.env.mainAppUrl}/logout`
        break;
      }
      case 'deleteAccount': {
        removeCookies()
        break
      }
      default:
        break
    }
  }
  handleClickOutside() {
    if (typeof (getCookie('accessToken')) === 'undefined' && (PATHS.indexOf(this.props.location.pathname.replace(/\/$/, '')) === -1) && this.props.location.pathname !== '/') {
      const redirectRoute = process.env.mainRoute
      if (process.env.standalone) {
        this.props.history.push(redirectRoute)
        // setFavicons()
      } else {
        window.location.href = getRedirectUrl('app', {}, {}, false)
      }
    }
  }
  showNotification(value, intl) {
    const {
      title, message, status, hideNotification, type
    } = value
    if (!hideNotification) {
      this.statusMessage.addNotification({
        title: intl.formatMessage({ id: title, defaultMessage: title }),
        message: intl.formatMessage({ id: `${type}.message`, defaultMessage: message }),
        level: status,
        autoDismiss: 4,
        position: 'tc'
      })
    }
  }
  render() {
    return (
      <Fragment>
        <NotificationSystem ref={(ref) => { this.statusMessage = ref }} />
        <OutsideClick onClickOutside={() => this.handleClickOutside()} />
        {getCookie('accessToken') && (
          <ErrorBoundary>
            <ChatBox />
          </ErrorBoundary>
        )}
      </Fragment>
    )
  }
}

Status.propTypes = {
  status: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string
  }),
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  statusCancel: PropTypes.func.isRequired,
  modalCloseAction: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  setDataLang: PropTypes.func.isRequired,
  LangSet: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  customMessage: PropTypes.func.isRequired,
}

Status.defaultProps = {
  status: {
    message: '',
    status: '',
    type: '',
    title: ''
  }
}

const mapStateToProps = state => ({
  status: state.status,
  lang: state.language.lang
})

export default withRouter(connect(mapStateToProps, {
  statusCancel, modalCloseAction, setDataLang, LangSet, customMessage
})(injectIntl(Status)))
