import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom'
import Main from './container/Main'
import Messages from '../common/lang/Messages'
import Status from '../common/container/Status'
import ModalComponent from '../common/container/Modal'
import UnifiedHeader from '../common/container/UnifiedHeader'
import Footer from '../common/container/Footer'
import Localization from '../common/lang'
import { BrowserSupport } from '../common/components';


const LangRoot = props => (
  <IntlProvider locale={props.lang} messages={props.messages ? props.messages[props.lang] : Messages[props.lang]}>
    <Router>
      <Fragment>
        <Status />
        <ModalComponent />
        <UnifiedHeader />
        <BrowserSupport>
          <Main />
        </BrowserSupport>
        <Footer />
      </Fragment>
    </Router>
  </IntlProvider>
)

LangRoot.propTypes = {
  lang: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  lang: state.language.lang
});

export default connect(mapStateToProps)(Localization()(LangRoot));
