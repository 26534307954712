import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ToolTip from 'react-portal-tooltip'
import { ClickOutside } from 'reusable-react-components'
import { withRouter } from 'react-router-dom'
import { style, getRedirectUrl, setAnalytics } from '../../../common/utils'
import TrendsGraph from '../../container/TrendsGraph'
import { CloseOnEscape } from '../../../common/components'

class SponsorListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isTooltipActive: false
    }
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)
    this.addToSearch = this.addToSearch.bind(this)
    this.excludeFromSearch = this.excludeFromSearch.bind(this)
    this.updateSearchQuery = this.updateSearchQuery.bind(this)
  }
  componentDidMount () {
    if (this.props.scrollId) {
      document.getElementById(this.props.scrollId).addEventListener('scroll', this.hideTooltip, false)
    }
  }
  componentWillUnmount () {
    if (this.props.scrollId) {
      document.getElementById(this.props.scrollId).removeEventListener('scroll', this.hideTooltip)
    }
  }
  showTooltip () {
    this.setState({ isTooltipActive: true })
  }

  hideTooltip () {
    this.setState({ isTooltipActive: false })
  }

  addToSearch () {
    const { data } = this.props
    const q = {
      values: data.name,
      type: data.type,
      class: data.class,
      id: data.id,
      operator: 'AND'
    }
    this.updateSearchQuery(q)
  }

  excludeFromSearch () {
    const { data } = this.props
    const q = {
      values: data.name,
      type: data.type,
      class: data.class,
      id: data.id,
      operator: 'NOT'
    }
    this.updateSearchQuery(q)
  }

  updateSearchQuery (newTerm) {
    this.props.onClickSearch(newTerm)
  }

  render () {
    const { data } = this.props
    const q = [{
      value: data.name,
      type: data.type,
      class: data.class,
      id: data.id,
      operator: ''
    }]
    const params = {
      doc: data.id,
      q: `@${data.name.replace(/ /g, '~')}::${data.id}`,
      type: data.type,
      class: data.class,
      id: data.id
    }
    return (
      <div className='list-item' ref={(element) => { this.element = element }} onClick={this.showTooltip} role='presentation' >
        {this.props.name}
        <CloseOnEscape onEscape={this.hideTooltip}>
          <ToolTip style={style} active={this.state.isTooltipActive} position='top' arrow='center' parent={this.state.isTooltipActive && this.element}>
            <ClickOutside onClickOutside={() => this.hideTooltip()} >
              <div className='tooltip-container'>
                <div>{this.props.name}</div>
                <TrendsGraph value={q} />
                <div className='search-filter-container'>
                  <p role='presentation' onClick={this.addToSearch} className='filter-element'>Add to search | </p>
                  <p role='presentation' onClick={this.excludeFromSearch} className='filter-element'>Exclude from search</p>
                </div>
                <div className='search-filter-container' >
                  {this.props.type === 'Authors' && (
                    <p
                      role='presentation'
                      onClick={() => {
                        setAnalytics('viewDetailsTrigger', 'viewDetails', getRedirectUrl('author', params));
                        window.location = getRedirectUrl('author', params)
                      }}
                      className='filter-element'
                    >
                      <FormattedMessage id="View Details" defaultMessage="View Details" />
                    </p>
                  )}
                </div>
              </div>
            </ClickOutside>
          </ToolTip>
        </CloseOnEscape>
      </div>
    )
  }
}

SponsorListItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClickSearch: PropTypes.func,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  scrollId: PropTypes.string,
}

SponsorListItem.defaultProps = {
  onClickSearch: null,
  scrollId: ''
}

export default withRouter(SponsorListItem)
