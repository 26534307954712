import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { stringify, parse } from 'query-string'
import { detect } from 'detect-browser';
import { logoutAction } from '../Login/logic'
import { getCookie, getRedirectUrl, ucFirst, setAnalytics, checkPermission, checkBrowserSupport } from '../../utils'
import { setDataLang, LangSet } from '../../lang/logic'
import { SimpleDropDown } from '../../components'
import { drawerAction } from '../Drawer/logic'
// import AlreadyLoggedIn from '../AlreadyLoggedIn';
// import { notificationCountAction } from '../Notification/logic'
import { modalAction } from '../Modal/logic'
import CustomerSupport from '../CustomerSupport'
import { DiscoverLinks } from '../constants'
import { getApps, getParticularApp } from '../utils'
// import CountDown from '../CountDown'

const open = {
  type: 'notification',
  open: true
}

const close = {
  type: '',
  open: false
}

const backUrlCheck = url => [
  `/${process.env.current}/notifications`, `/${process.env.current}/subscriptions`, '/guidelinewatch/details', '/guidelinewatch/comparison'
].indexOf(url)

const renderApps = () => {
  if (getCookie('accessToken')) {
    const Apps = getApps()
    const { appLabel } = parse(window.location.hash);
    return Apps.map((item, i) => {
      const childApps = getParticularApp(item.name)
      return (
        <li key={i} className={`left-nav-group-item ${item.name === 'Dashboards' ? 'active' : ''} unified-apps-dropdown`}>
          <a className='nav-link-item' href={childApps.length === 1 ? childApps[0][childApps[0].status] : undefined} >
            {item.name}
          </a>
          {childApps && childApps.length > 1 &&
            <div className='unified-apps-dropdown-content' >
              {childApps.map((app, j) => {
                const paths = app.inactive.split('/');
                let className = '';
                if (window.location.pathname.includes(`/${paths[paths.length - 1]}`)) {
                  className = 'active';
                }
                if (!className) {
                  if (app.name === appLabel) {
                    className = 'active';
                  }
                }
                return (
                  <a className={className} key={`${i}-${j}-app-dropdown`} href={app[app.status]} >{app.name}</a>
              )
})}
            </div>
          }
        </li>
      )
    })
  }
  return null
}

const renderProductTitle = () => {
  const Apps = getApps();
  const App = Apps[1].childrenApps.find((el) => {
    const paths = el.inactive.split('/')
    return window.location.pathname.includes(`/${paths[paths.length - 1]}`)
  })
  if (App) {
    return <a href={App.active} className="product-title">{App.name}</a>
  }
  const { appLabel } = parse(window.location.hash);
  const App1 = Apps[1].childrenApps.find(el => appLabel === el.name);
  if (App1) {
    return <a href={App1.active} className="product-title">{App1.name}</a>
  }
  return null
}
class UnifiedHeader extends Component {
  state = {
    lang: this.props.lang,
    langTitle: this.props.lang === 'de' ? 'Deutsch' : (this.props.lang === 'fr' ? 'French' : 'English')
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.lang !== nextProps.lang) {
      this.setState({
        langTitle: nextProps.lang === 'de' ? 'Deutsch' : (nextProps.lang === 'fr' ? 'French' : 'English')
      })
    }
  };

  onLangSelection = (lang) => {
    let langCode;
    let langTitle;
    switch (lang) {
      case 'Deutsch': {
        langCode = 'de';
        langTitle = 'Deutsch';
        break;
      }
      case 'French': {
        langCode = 'fr';
        langTitle = 'French';
        break;
      }
      default: {
        langCode = 'en';
        langTitle = 'English'
      }
    }
    if (langCode !== this.state.lang) {
      this.setState(() => ({
        lang: langCode,
        langTitle
      }));
      this.props.LangSet(langCode);
      if (langCode !== this.props.dataLang) {
        this.props.setDataLang(langCode);
      }
    }
  };

  onNotificationClick() {
    if (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)) {
      const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
      const url = getRedirectUrl(process.env.current, {}, {}, false)
      const params = stringify({
        ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
        backApp: process.env.backLabel
      })
      window.location = `${url}/notifications?${params}`
    } else {
      this.props.drawerAction(this.props.drawer.open ? close : open)
      if (this.props.drawer.open) {
        // this.props.notificationCountAction()
      }
    }
  }

  onSubAppsClick(url, permission) {
    if (checkPermission(permission)) {
      this.props.modalAction({
        type: 'premium',
        open: true,
        dialog: true,
        size: 'large'
      })
    } else {
      window.location.href = url;
    }
  }

  openModal() {
    this.props.modalAction({
      type: 'login',
      open: true,
      dialog: true,
      size: 'medium'
    })
  }
  renderLocalApps () {
    let items = null;
    items = DiscoverLinks.map((item, i) => {
      if (checkPermission(item.permission) || (item.name === 'Marketplace' && checkPermission(item.academic))) {
        return (
          <li key={i} className={`left-nav-group-item ${this.props.location.pathname.includes(item.active) ? 'active' : ''}`}>
            <a onClick={() => this.onSubAppsClick(item.link, item.academic)} href={item.link}className='nav-link-item' >
              {checkPermission(item.academic) && <span className='card-premium padding-sm-right' />} <FormattedMessage id={item.id} defaultMessage={item.name} />
            </a>
          </li>
        )
      }
      return null
    });
    return items;
  }
  renderLanguage () {
    const { intl, location } = this.props
    const languagesOption = ['english', 'Deutsch', 'French'];
    if ((getCookie('accessToken') && checkPermission('language_localization') && !location.pathname.includes('/guidelinewatch/')) || !getCookie('accessToken')) {
      return (
        <li className='language-item right-nav-group-item'>
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={languagesOption}
            title={intl.formatMessage({ id: this.state.langTitle || 'abc', defaultMessage: this.state.langTitle })}
            onSelect={index => this.onLangSelection(languagesOption[index])}
          />
        </li>
      )
    }
    return null
  }
  renderAcademic () {
    const { intl } = this.props
    if (!getCookie('accessToken')) {
      return (
        <li className='right-nav-group-item'>
          <a title={intl.formatMessage({ id: 'Academic access', defaultMessage: 'Academic access' })} style={{ color: '#ffffff' }} href='/academic'><FormattedMessage id="Academic Access" defaultMessage="Academic Access" /></a>
        </li>
      )
    }
    return null
  }
  renderNotification () {
    if (getCookie('accessToken') && ((checkPermission('marketplace_permission') && !checkPermission('ontosight_academics_user')) || (process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)))) {
      const count = process.env.standaloneNotifications && checkPermission(`${process.env.current}_notification_permission`)
        ? this.props.notification.count.unread_count
        : this.props.count.data
      const { intl } = this.props
      return (
        <li className='right-nav-group-item notification-drawer'>
          <a title={intl.formatMessage({ id: 'Notifications', defaultMessage: 'Notifications' })} role='presentation' className='notification-icon-wrap' onClick={() => this.onNotificationClick()} >
            <i className='notification-icon' />
            {count > 0 && <span className='badge-count'>{count}</span>}
          </a>
        </li>
      )
    }
    return null
  }
  renderProfile () {
    if (getCookie('accessToken')) {
      const { intl } = this.props;
      const userOptions = [
        {
          name: <FormattedMessage id="Header.FAQ's" defaultMessage="FAQ's" />,
          title: intl.formatMessage({ id: 'Header.Frequently asked questions', defaultMessage: 'Frequently asked questions' }),
          cb: () => {
            setAnalytics('pageFromWhereFaqsClickedTrigger', 'pageFromWhereFaqsClicked', this.props.location.pathname);
            window.location.href = `${process.env.mainAppUrl}/faq`
          }
        },
        {
          name: <FormattedMessage id="Header.Profile" defaultMessage="Profile" />,
          title: intl.formatMessage({ id: 'Header.More about you', defaultMessage: 'More about you' }),
          cb: () => { window.location.href = `${process.env.mainAppUrl}/profile` }
        },
        {
          name: <FormattedMessage id="Header.AccountSettings" defaultMessage="Account Settings" />,
          title: intl.formatMessage({ id: 'Header.Check / Update your settings', defaultMessage: 'Check / Update your settings' }),
          cb: () => { window.location.href = `${process.env.mainAppUrl}/settings` }
        },
        {
          name: <FormattedMessage id="Header.Subscriptions" defaultMessage="Subscriptions" />,
          cb: () => {
            const backUrl = `${window.location.origin}${window.location.pathname}?${stringify({ ...parse(window.location.search) })}`
            const url = getRedirectUrl(process.env.current, {}, {}, false)
            const params = stringify({
              ...(backUrlCheck(this.props.location.pathname) === -1 ? { backUrl } : { backUrl: parse(this.props.location.search).backUrl || backUrl }),
              backApp: process.env.backLabel
            })
            window.location = `${url}/subscriptions?${params}`
          },
          title: intl.formatMessage({ id: 'Header.Check / Update your subscriptions', defaultMessage: 'Check / Update your subscriptions' }),
          condition: !!process.env.standaloneNotifications && !!checkPermission(`${process.env.current}_notification_permission`)
        },
        // { name: 'On Boarding', cb: () => { window.location = `${getRedirectUrl('app', {}, {}, false)}marketplace/#/home/onboarding/affiliations/` } },
        {
          name: <FormattedMessage id="Header.Logout" defaultMessage="Logout" />,
          title: intl.formatMessage({ id: 'Header.See you next time!', defaultMessage: 'See you next time!' }),
          cb: () => {
            setAnalytics('logoutTrigger', 'logout', getCookie('userName'));
            this.props.logoutAction(stringify({ app: this.props.app, trialUser: !!getCookie('trialUser') }))
          }
        }
      ]
      const filteredUserOptions = userOptions.filter(opt => !('condition' in opt) || opt.condition)
      const profileIcon = `<div title='${ucFirst(getCookie('name') || 'Guest')}' class='disp-flex vcenter' ><div class='user-img'>
      <img class='user-face' src='/src/assets/images/users.svg' alt='Profile' />
      </div><div class='nav-item text-elips'>${ucFirst(getCookie('name') || 'Guest')}</div></div>`
      return (
        <li className='user-item' >
          <SimpleDropDown
            DropdownClass='has-arrow'
            options={filteredUserOptions}
            title={profileIcon}
            onSelect={(index) => { filteredUserOptions[index].cb() }}
          />
        </li>
      )
    }
    return null
  }

  renderLogin () {
    if (!getCookie('accessToken')) {
      return (
        <li role='presentation' onClick={() => this.openModal()} className='right-nav-group-item'>
          <FormattedMessage id="LOGIN" defaultMessage="LOGIN" />
        </li>
      )
    }
    return null
  }
  render() {
    const query = parse(window.location.hash).alreadyLoggedIn
    const hash = parse(window.location.search).alreadyLoggedIn
    const style = {
      ...!getCookie('accessToken') && (query === 'true' || hash === 'true') ? { display: 'none' } : {}
    }
    const Apps = getApps();
    const { appLabel } = parse(window.location.hash);
    const App1 = Apps[1].childrenApps.find(el => appLabel === el.name);

    return (
      <header id='header-container' className='unified-header'>
        <div style={style} className='unified-header-top-container'>
          <ul className='left-nav-group'>
            <li className='nav-logo-wrap' >
              <a href={App1 && App1.active} className='logo' >
                {/* {checkPermission('ontosight_academics_user') ? <i className='icon icon-logo-iplexus-academics' /> : <i className='icon icon-logo-iplexus' />} */}
                <i className='icon icon-logo-iplexus' />
              </a>
            </li>
            {renderApps()}
          </ul>
          <ul className='right-nav-group'>
            <li className='right-nav-group-item'>
              <CustomerSupport />
            </li>
            {this.renderAcademic()}
            {this.renderProfile()}
            {this.renderLogin()}
          </ul>
        </div>
        {getCookie('accessToken') && (checkPermission('loadMedicalDashboard') || checkPermission('loadRegulatoryDashboard') || checkPermission('loadGuidelinesWatchDashboard') || checkPermission('loadCustomDashboard')) && checkBrowserSupport(detect()) &&
          <div className='unified-header-bottom-container'>
            <ul className='left-nav-group'>
              {/* {this.renderLocalApps()} */}
              {renderProductTitle()}
            </ul>
            <ul className='right-nav-group'>
              {this.renderLanguage()}
              {/* {this.renderNotification()} */}
            </ul>
          </div>
        }
      </header>
    )
  }
}

UnifiedHeader.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  drawerAction: PropTypes.func.isRequired,
  drawer: PropTypes.object.isRequired,
  count: PropTypes.object.isRequired,
  modalAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  notification: PropTypes.object,
  app: PropTypes.string,
  LangSet: PropTypes.func.isRequired,
  setDataLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  dataLang: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
}
UnifiedHeader.defaultProps = {
  notification: {},
  app: 'facelift'
}

const mapStateToProps = state => ({
  drawer: state.drawer,
  count: state.count,
  notification: state.notificationList,
  lang: state.language.lang,
  dataLang: state.language.dataLang
})

export default withRouter(connect(mapStateToProps, {
  logoutAction, drawerAction, modalAction, setDataLang, LangSet
})(injectIntl(UnifiedHeader)))
