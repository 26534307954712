import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../Login/logic'
// import { CREATE_BOOKMARK_SUCCESS, DELETE_BOOKMARK_SUCCESS } from '../Bookmark/logic'
import { ARTICLE_FEEDBACK_SUCCESS, ARTICLE_FEEDBACK_FAILURE } from '../LikeDislike/logic'

import { SAVE_CLUSTER_SUCCESS } from '../../../app/container/SaveAsCluster/logic'

import { CREATE_BOOKMARK_SUCCESS, CREATE_FOLDER_SUCCESS, EDIT_BOOKMARK_SUCCESS, DELETE_BOOKMARK_SUCCESS, DELETE_FOLDER_SUCCESS } from '../BookmarkModal/logic'
// import { USER_SUBSCRIPTION_UPDATE_SUCCESS } from '../../../guidelinewatch/container/SubscriptionsModal/logic'
import { CREATE_BIBLIOGRAPHY_SUCCESS, CREATE_BIBLIOGRAPHY_FOLDER_SUCCESS, EDIT_BIBLIOGRAPHY_SUCCESS, DELETE_BIBLIOGRAPHY_SUCCESS, DELETE_BIBLIOGRAPHY_FOLDER_SUCCESS } from '../BibliographyModal/logic'
import { TRIAL_EXPIRED } from '../CountDown/logic'
import { REQUEST_DEMO_SUCCESS } from '../RequestDemo/logic'

export const ERROR = 'ERROR'
export const STATUS_CANCEL = 'STATUS_CANCEL'
const CUSTOM_MESSAGE = 'CUSTOM_MESSAGE'
const INITAL_STATE = {
  message: '',
  status: '',
  type: '',
  title: ''
}

export function statusCancel () {
  return {
    type: STATUS_CANCEL
  }
}

export function statusError (payload) {
  return {
    type: ERROR,
    payload
  }
}

export function customMessage (payload) {
  return {
    type: CUSTOM_MESSAGE,
    payload
  }
}

function setErrorStatus (payload) {
  const obj = {
    message: 'Oops! There has been an issue. Re-try in some time.',
    status: 'error',
    type: '400',
    title: 'Error'
  }
  switch (payload.status) {
    case 401:
      obj.message = 'Your current session has expired.'
      obj.type = '401'
      break
    case 403:
      obj.message = "You don't have required permissions, Please contact our adimin."
      obj.type = '403'
      break
    case 409:
      obj.message = payload.response.message || 'Oops! There has been an issue. Re-try in some time.'
      obj.type = '409'
      break
    default:
      break
  }
  return obj
}

export function statusReducer (state = INITAL_STATE, action = null) {
  switch (action.type) {
    case STATUS_CANCEL: {
      return INITAL_STATE
    }
    case ERROR: {
      const obj = setErrorStatus(action.payload)
      return obj
    }
    case ARTICLE_FEEDBACK_FAILURE: {
      const obj = setErrorStatus(action.status || '')
      return obj
    }
    case LOGIN_SUCCESS: {
      return {
        message: 'You are successfully logged in',
        status: 'success',
        type: 'login',
        title: 'Success',
        hideNotification: true
      }
    }
    // case DELETE_ACCOUNT_SUCCESS: {
    //   return {
    //     message: 'You account has successfully deleted',
    //     status: 'success',
    //     type: 'deleteAccount',
    //     title: 'Success'
    //   }
    // }
    case CUSTOM_MESSAGE: {
      return {
        message: action.payload.message || '',
        status: action.payload.status || 'success',
        type: 'customMessage',
        title: action.payload.title || 'Success'
      }
    }
    case CREATE_FOLDER_SUCCESS: {
      return {
        message: 'Folder has been created',
        status: 'success',
        type: 'potliCreate',
        title: 'Success'
      }
    }
    case DELETE_FOLDER_SUCCESS: {
      return {
        message: 'Folder has been deleted',
        status: 'success',
        type: 'potliDelete',
        title: 'Success'
      }
    }
    case CREATE_BIBLIOGRAPHY_FOLDER_SUCCESS: {
      return {
        message: 'Bibliography folder has successfully created',
        status: 'success',
        type: 'bibliographyCreate',
        title: 'Success'
      }
    }
    case DELETE_BIBLIOGRAPHY_FOLDER_SUCCESS: {
      return {
        message: 'Bibliography folder has successfully deleted',
        status: 'success',
        type: 'bibliographyDelete',
        title: 'Success'
      }
    }
    // case USER_SUBSCRIPTION_UPDATE_SUCCESS: {
    //   return {
    //     message: 'Your subscription list has been updated',
    //     status: 'success',
    //     type: 'notificationGuideline',
    //     title: 'Success'
    //   }
    // }
    case SAVE_CLUSTER_SUCCESS: {
      return {
        message: 'Cluster has successfully created and go to MY WORKSPACE to view your saved cluster',
        status: 'success',
        type: 'saveCluster',
        title: 'Success'
      }
    }
    // case EDIT_CLUSTER_SUCCESS: {
    //   return {
    //     message: 'Cluster has successfully edited',
    //     status: 'success',
    //     type: 'editCluster',
    //     title: 'Success'
    //   }
    // }
    // case DELETE_CLUSTER_SUCCESS: {
    //   return {
    //     message: 'Cluster has successfully deleted',
    //     status: 'success',
    //     type: 'deleteCluster',
    //     title: 'Success'
    //   }
    // }
    // case DELETE_DATA_SUCCESS: {
    //   return {
    //     message: 'You have successfully deleted all your user activity',
    //     status: 'success',
    //     type: 'deleteData',
    //     title: 'Success'
    //   }
    // }
    case ARTICLE_FEEDBACK_SUCCESS: {
      return {
        message: 'Thank you for your feedback',
        status: 'success',
        type: 'article',
        title: 'Success'
      }
    }
    case CREATE_BOOKMARK_SUCCESS: {
      return {
        message: 'Bookmark created successfully',
        status: 'success',
        type: 'bookmark',
        title: 'Success'
      }
    }
    case EDIT_BOOKMARK_SUCCESS: {
      return {
        message: 'Bookmark edit successfully',
        status: 'success',
        type: 'editBookmark',
        title: 'Success'
      }
    }
    case DELETE_BOOKMARK_SUCCESS: {
      return {
        message: 'Bookmark removed successfully',
        status: 'success',
        type: 'deleteBookmark',
        title: 'Success'
      }
    }
    case CREATE_BIBLIOGRAPHY_SUCCESS: {
      return {
        message: 'Bibliography created successfully',
        status: 'success',
        type: 'bibliography',
        title: 'Success'
      }
    }
    case EDIT_BIBLIOGRAPHY_SUCCESS: {
      return {
        message: 'Bibliography edit successfully',
        status: 'success',
        type: 'editBibliography',
        title: 'Success'
      }
    }
    case DELETE_BIBLIOGRAPHY_SUCCESS: {
      return {
        message: 'Bibliography removed successfully',
        status: 'success',
        type: 'deleteBibliography',
        title: 'Success'
      }
    }
    case LOGOUT_SUCCESS: {
      return {
        message: 'You are successfully logged out',
        status: 'success',
        type: 'logout',
        title: 'Success'
      }
    }
    case REQUEST_DEMO_SUCCESS: {
      return {
        message: 'Thank you for requesting demo, We will get back to you soon.',
        status: 'success',
        type: 'requestDemo',
        title: 'Success'
      }
    }
    case TRIAL_EXPIRED: {
      return {
        message: 'Your trial period has been expired, Please click on the registration link received in your inbox to continue.',
        status: 'info',
        type: 'trial-expiry',
        title: 'Please complete the registration!'
      }
    }
    default:
      return state
  }
}
