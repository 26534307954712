import React from 'react'
import PropTypes from 'prop-types'

const SpecialLoader = ({ height }) => (
  <div className='big-loader loader' style={{ height }}>
    <div className='spinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
      <div className="loading-text">Loading Dashboards...</div>
    </div>
  </div>
)

SpecialLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SpecialLoader.defaultProps = {
  height: '100vh'
}

export default SpecialLoader
