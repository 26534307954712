import { getMinMaxValue, getTimeStamp, getDate, getYear, getMonth } from '../../common/components/DatePicker/utils'
import { getCookie } from '../utils';

const now = getMinMaxValue('month', true, getTimeStamp())
export const startDate = value => getMinMaxValue('month', false, getTimeStamp(getYear(now) - value, getMonth(now), getDate(now)))
export const customDateOptions = [{
  name: 'Last 10 years',
  endDate: now,
  startDate: startDate(9)
}, {
  name: 'Last 5 years',
  endDate: now,
  startDate: startDate(4)
}, {
  name: 'Last 2 Years',
  endDate: now,
  startDate: startDate(1)
}, {
  name: 'This year',
  endDate: now,
  startDate: startDate(0)
}]
export const disableBeforeDate = getMinMaxValue('month', false, new Date('1970-1-1').getTime())
export const disableAfterDate = getMinMaxValue('month', true, new Date().getTime())

export const formApps = (allActiveApps = []) => {
  const { activeApps = [] } = process.env
  activeApps.forEach((apps, i) => {
    apps.childrenApps.forEach((item, j) => {
      const app = allActiveApps.find(a => a.id === item.id)
      if (app) {
        activeApps[i].childrenApps[j].status = app.status || 'inactive'
      }
    })
  })
  return activeApps
}

export const getApps = () => getCookie('activeApps') || process.env.activeApps || []

export const getParticularApp = (appName) => {
  const Apps = getApps()
  if (Apps && Apps.length) {
    const app = Apps.find(item => item.name === appName)
    if (app && app.childrenApps && Array.isArray(app.childrenApps)) {
      return app.childrenApps
    }
  }
  return []
}

export const getActiveApp = (appName) => {
  const Apps = getParticularApp(appName)
  return !!Apps.find(item => window.location.pathname.includes(item.inactive)) || false
}

export const getAllApps = () => {
  const Apps = getApps()
  let allApps = []
  if (Apps && Apps.length) {
    Apps.forEach((item) => {
      if (item.childrenApps && Array.isArray(item.childrenApps)) {
        allApps = [...allApps, ...item.childrenApps]
      }
    })
  }
  return allApps
}
