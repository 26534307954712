import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
import Bookmark from '../../container/Bookmark'
import Bibliography from '../../container/Bibliography'
import { ClickableAuthors, ListIcons, AssetTag } from '../'
import { generateStringArray, checkPermission } from '../../utils'
import ShowMore from '../ShowMore'

const PublicationList = (props) => {
  const renderTags = () => {
    let tags = []
    if (props.data.Tags && props.data.Tags.length) {
      tags = generateStringArray(props.data.Tags, 'name')
    }
    return tags.length && (<ShowMore data={[{ value: tags }]} />)
  }
  const style = {
    top: 3,
    right: 15,
    zIndex: 200,
    position: 'absolute'
  }
  return (
    <Fragment>
      {!!props.data['Citation count'] && !props.showMore && checkPermission('citation_index') && (
        <a
          role='presentation'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.openCard();
          }}
          style={style}
          className='card-text text-link'
        >
          <FormattedMessage id="Cited by" defaultMessage="Cited by" /> - {props.data['Citation count']}
        </a>
      )}
      {props.flag &&
        <div className='card-actions'>
          <Bookmark flag={props.data.bookmark} type={props.type} id={props.data.doc_id} group='Published' />
          <div className='margin-lg-top' >
            <Bibliography flag={props.data.bibliography || false} type={props.type} id={props.data.doc_id} group='Published' />
          </div>
        </div>
      }
      <div className='card-details'>
        <div className='card-meta'>
          <AssetTag asset={props.data.type} />
          {props.data['Journal Title'] ? <div className='card-meta-title text-elips'>{props.data['Journal Title']}</div> : null}
          {props.data['Publication Date'] ? <div className='date'>{props.data['Publication Date']}</div> : null}
          {props.data['Full Text Link'] ? <a className='is-clickable handicon padding-small-left' onClick={e => e.stopPropagation()} target='_balnk' rel="noopener noreferrer" href={props.data['Full Text Link']}><FormattedMessage id="Has full text" defaultMessage="Has full text" /></a> : null}
        </div>
        {props.data['Article Title']
          ? (
            <h3 className='card-title'>
              {ReactHtmlParser(props.data['Article Title'])}
            </h3>
          ) : null}
        {!props.showMore && props.data.Authors && props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0 && (
          <div className='card-text' >
            <ClickableAuthors {...props} limit={3} />
          </div>
        )}
        {props.showMore
          ? (
            <Fragment>
              {props.data.Authors && props.data.Authors.length > 0 && props.data.Authors.map(author => author.author_name).join('').length > 0 && (
                <div className='card-body-content' >
                  <div className='sub-title'><FormattedMessage id="Authors" defaultMessage="Authors" />:</div>
                  <ClickableAuthors {...props} />
                </div>)}
              {props.data.Tags && props.data.Tags.length > 0 && (
                <div className='card-body-content' >
                  <div className='sub-title'><FormattedMessage id="FINGERPRINTS" defaultMessage="FINGERPRINTS" />:</div>
                  <div className='tag-list-wrap'>{renderTags()}</div>
                </div>)}
            </Fragment>
          ) : null}
      </div>
      {props.overlay && <ListIcons {...props} data={props.data} likeParams={props.likeParams} takeFeedback={props.takeFeedback} />}
    </Fragment>
  )
}

PublicationList.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  flag: PropTypes.bool,
  showMore: PropTypes.bool,
  likeParams: PropTypes.object,
  takeFeedback: PropTypes.bool,
  overlay: PropTypes.bool,
  openCard: PropTypes.func,
}

PublicationList.defaultProps = {
  flag: true,
  showMore: false,
  likeParams: {},
  takeFeedback: true,
  overlay: true,
  openCard: null
}

export default PublicationList
