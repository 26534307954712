import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { ClickOutside } from 'reusable-react-components'
import Calendar from './calendar'
import { getMinMaxValue, formatDate } from './utils'
import CustomDropdown from './custom'

class DatePicker extends Component {
  constructor (props) {
    super(props)
    const startDate = getMinMaxValue(this.props.type, false, this.props.startDate)
    const endDate = getMinMaxValue(this.props.type, true, this.props.endDate)
    const custom = this.setCustom(this.props.type, this.props.customOptions, startDate, endDate)
    this.state = {
      open: this.props.open,
      startDate,
      endDate: endDate >= startDate ? endDate : startDate,
      disableAfterDate: this.props.disableAfterDate,
      disableBeforeDate: this.props.disableDependsOnStart ? startDate : this.props.disableBeforeDate,
      ...custom
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.startDate !== this.props.startDate || nextProps.endDate !== this.props.endDate) {
      const startDate = getMinMaxValue(nextProps.type, false, nextProps.startDate)
      const endDate = getMinMaxValue(nextProps.type, true, nextProps.endDate)
      const custom = this.setCustom(nextProps.type, nextProps.customOptions, startDate, endDate)
      this.setState({
        startDate,
        endDate: endDate >= startDate ? endDate : startDate,
        disableAfterDate: nextProps.disableAfterDate,
        disableBeforeDate: nextProps.disableDependsOnStart ? startDate : nextProps.disableBeforeDate,
        ...custom
      })
    }
  }
  outsideClick = () => {
    const startDate = getMinMaxValue(this.props.type, false, this.props.startDate)
    const endDate = getMinMaxValue(this.props.type, true, this.props.endDate)
    const custom = this.setCustom(this.props.type, this.props.customOptions, startDate, endDate)
    this.setState({
      ...custom,
      open: false,
      startDate,
      endDate: endDate >= startDate ? endDate : startDate,
      disableAfterDate: this.props.disableAfterDate,
      disableBeforeDate: this.props.disableDependsOnStart ? startDate : this.props.disableBeforeDate
    })
  }
  setCustom (type, customObject, startDate, endDate) {
    const custom = {
      name: 'Custom',
      startDate,
      endDate
    }
    const customOptions = [
      ...customObject,
      custom
    ]
    const active = customOptions.find(item => getMinMaxValue(type, false, item.startDate) === startDate && getMinMaxValue(this.props.type, true, item.endDate) === endDate)
    return {
      customOptions,
      active
    }
  }
  onChange = (value, key) => {
    this.setState({
      [key]: value,
      ...(key === 'startDate' ? {
        disableBeforeDate: this.props.disableDependsOnStart ? value : this.state.disableBeforeDate,
        endDate: value > getMinMaxValue(this.props.type, false, this.state.endDate) ? getMinMaxValue(this.props.type, true, value) : this.state.endDate
      } : {})
    }, () => {
      const custom = this.setCustom(this.props.type, this.props.customOptions, this.state.startDate, this.state.endDate)
      this.setState(custom)
    })
  }
  onSubmit = () => {
    this.setState({
      open: false
    }, () => {
      if (this.props.onSubmit) {
        this.props.onSubmit(getMinMaxValue(this.props.type, false, this.state.startDate), getMinMaxValue(this.props.type, true, this.state.endDate))
      }
    })
  }
  onCustomClick = (item) => {
    this.onChange(item.startDate, 'startDate')
    this.onChange(item.endDate, 'endDate')
  }
  renderBox () {
    const format = {
      year: { year: 'numeric' },
      month: { month: 'short', year: 'numeric' },
      date: { day: '2-digit', month: 'short', year: 'numeric' }
    }
    return (
      <button onClick={() => this.setState({ open: !this.state.open })} className='s-date-picker-box' >
        {formatDate(this.props.startDate, format[this.props.type])} - {formatDate(this.props.endDate, format[this.props.type])}
      </button>
    )
  }
  render() {
    const { intl } = this.props
    return (
      <div className='padding-medium-right' >
        {this.props.label && <div style={{ height: 25 }} className='filter-blk-label vcenter' ><div>{this.props.label}</div><div className='required-red'>*</div></div>}
        <ClickOutside onClickOutside={e => this.outsideClick(e)}>
          <div className="s-date-picker">
            {this.renderBox()}
            {this.state.open && (
              <div className={`s-date-picker-container pos-${this.props.position} ${this.props.type}`} >
                <div className='s-date-picker-container-header' >
                  {this.state.customOptions.length > 1 && <CustomDropdown active={this.state.active} onCustomClick={this.onCustomClick} options={this.state.customOptions} />}
                </div>
                <div className='s-date-picker-content' >
                  <Calendar id='startDate' disableAfterDate={this.state.disableAfterDate} disableBeforeDate={this.props.disableBeforeDate} date={this.state.startDate} title={intl.formatMessage({ id: 'Start Date', defaultMessage: 'Start Date' })} type={this.props.type} onChange={value => this.onChange(value, 'startDate')} />
                  <Calendar id='endDate' disableAfterDate={this.state.disableAfterDate} disableBeforeDate={this.state.disableBeforeDate} date={this.state.endDate} title={intl.formatMessage({ id: 'End Date', defaultMessage: 'End Date' })} type={this.props.type} onChange={value => this.onChange(value, 'endDate')} endRange />
                </div>
                <div className='s-date-picker-container-footer' >
                  <button onClick={this.outsideClick} className='s-footer-btn cancel' >Cancel</button>
                  <button onClick={this.onSubmit} className='s-footer-btn apply' ><FormattedMessage id="Apply" defaultMessage="Apply" /></button>
                </div>
              </div>
            )}
          </div>
        </ClickOutside>
      </div>
    );
  }
}

DatePicker.propTypes = {
  open: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  onSubmit: PropTypes.func,
  disableAfterDate: PropTypes.number,
  disableBeforeDate: PropTypes.number,
  disableDependsOnStart: PropTypes.bool,
  customOptions: PropTypes.array,
  position: PropTypes.string,
  intl: PropTypes.object.isRequired
}

DatePicker.defaultProps = {
  open: false,
  label: '',
  type: 'date',
  startDate: new Date().getTime(),
  endDate: new Date().getTime(),
  onSubmit: () => null,
  disableAfterDate: undefined,
  disableBeforeDate: undefined,
  disableDependsOnStart: false,
  customOptions: [],
  position: 'left'
}

export default injectIntl(DatePicker);
