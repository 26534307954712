import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser'
import { readableDate } from '../../../common/utils'

const GuideLinesNotify = (props) => {
  const renderAuthors = () => props.data.concept.map((item, i) => (
    <span className='author-name' key={i} >{item}{i !== props.data.concept.length - 1 ? ', ' : '.'}</span>
  ))
  return (
    <Fragment>
      <div className='card-details'>
        {'is_read' in props.data && (
          <div className='card-meta padding-medium-bottom has-space-btwn vcenter guideline-notify-card-list'>
            <div className={`date ${!props.data.is_read ? 'unread' : ''}`}><span className={`${!props.data.is_read ? 'margin-medium-left' : ''}`} ><FormattedMessage id="GUIDELINE" defaultMessage="GUIDELINE" /></span></div>
          </div>
        )}
        <h3 className='card-title'>
          {ReactHtmlParser(props.data.body)}
        </h3>

        <div className='card-text'>
          {
            props.data.concept && props.data.concept.length > 0 && (
              <div className='card-subtext'>
                {renderAuthors()}
              </div>
          )}
          {
            props.data.created_at && (
              <div className='card-subtext'>
                  {readableDate(props.data.notification_timestamp)}
              </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

GuideLinesNotify.propTypes = {
  data: PropTypes.object.isRequired,
}

GuideLinesNotify.defaultProps = {
}

export default GuideLinesNotify
