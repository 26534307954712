import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl';

const Patent = (props) => {
  const renderClaims = () => props.data.Claims.map((item, i) => (
    <div key={i} >{ReactHtmlParser(item)}</div>
  ))
  const abstract = props.data.Abstract.replace(/<(?!span)(?!\/)/g, '< ')
  return (
    <Fragment>
      {props.data.Abstract && (
      <div className='card-body-content'>
        <div className='sub-title' ><FormattedMessage id="Abstract" defaultMessage="Abstract" /></div>
        <div id='annotation-highlight-1' >{ReactHtmlParser(abstract)}</div>
      </div>)}
      {props.data.Claims.length > 0 && (
      <div className='card-body-content'>
        <div className='sub-title'><FormattedMessage id="Claims" defaultMessage="Claims" /></div>
        <div>{renderClaims()}</div>
      </div>)}
    </Fragment>
  )
}

Patent.propTypes = {
  data: PropTypes.object.isRequired
}

export default Patent
