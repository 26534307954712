import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/takeUntil'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

// Constants
const ADVANCED_EDITABLE_AUTO_COMPLETE = 'ADVANCED_EDITABLE_AUTO_COMPLETE'
export const ADVANCED_EDITABLE_AUTO_COMPLETE_SUCCESS = 'ADVANCED_EDITABLE_AUTO_COMPLETE_SUCCESS'
const ADVANCED_EDITABLE_AUTO_COMPLETE_FAILURE = 'ADVANCED_EDITABLE_AUTO_COMPLETE_FAILURE'
const ADVANCED_EDITABLE_AUTO_COMPLETE_CANCEL = 'ADVANCED_EDITABLE_AUTO_COMPLETE_CANCEL'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
  class: ''
}

// Auto Complete cancel action
export const advancedEditableAutoCompleteCancelAction = () => ({
  type: ADVANCED_EDITABLE_AUTO_COMPLETE_CANCEL
})

// Auto Complete action
export const advancedEditableAutoCompleteAction = payload => ({
  type: ADVANCED_EDITABLE_AUTO_COMPLETE,
  payload
})

// Auto Complete Success action
const advancedEditableAutoCompleteSuccess = payload => ({
  type: ADVANCED_EDITABLE_AUTO_COMPLETE_SUCCESS,
  payload
})

// Auto Complete epic
export const advancedEditableAutoCompleteEpic = action$ => action$
  .ofType(ADVANCED_EDITABLE_AUTO_COMPLETE)
  .debounceTime(500)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/search/autocomplete?${action.payload.params}`, 'GET'))
    .map(response => advancedEditableAutoCompleteSuccess(response))
    .takeUntil(action$.ofType(ADVANCED_EDITABLE_AUTO_COMPLETE_CANCEL))
    .catch(error => Observable.of({
      type: ADVANCED_EDITABLE_AUTO_COMPLETE_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

// Auth reducer updates both advancedEditableAutoComplete and logout
export function advancedEditableAutoCompleteReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case ADVANCED_EDITABLE_AUTO_COMPLETE: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false,
        class: action.payload.class
      }
    }
    case ADVANCED_EDITABLE_AUTO_COMPLETE_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data.length ? action.payload.response.data[0] : {},
        loading: false,
        error: false,
        flag: true
      }
    }
    case ADVANCED_EDITABLE_AUTO_COMPLETE_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
