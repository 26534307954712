import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { detect } from 'detect-browser'
import BrowserSupportPage from '../BrowserSupportPage'
import { checkBrowserSupport } from '../../utils'
import AlreadyLoggedIn from '../../container/AlreadyLoggedIn';

class BrowserSupport extends Component {
  constructor() {
    super()
    const browser = detect()

    this.isBrowserSupported = checkBrowserSupport(browser);
  }
  render() {
    if (!this.isBrowserSupported) {
      return <BrowserSupportPage />
    }

    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

BrowserSupport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

BrowserSupport.defaultProps = {
  children: null
}

export default withRouter(AlreadyLoggedIn()(BrowserSupport))
