import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/observable/of'
import { apiCall } from '../../../common/utils'
import { ERROR } from '../../../common/container/Status/logic'

const SAVE_CLUSTER = 'SAVE_CLUSTER'
export const SAVE_CLUSTER_SUCCESS = 'SAVE_CLUSTER_SUCCESS'
const SAVE_CLUSTER_CANCEL = 'SAVE_CLUSTER_CANCEL'
const SAVE_CLUSTER_FAILURE = 'SAVE_CLUSTER_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false
}

export const saveClusterFetchAction = payload => ({
  type: SAVE_CLUSTER,
  payload
})

const saveClusterFetchSuccess = payload => ({
  type: SAVE_CLUSTER_SUCCESS,
  payload
})

export const saveClusterFetchCancel = payload => ({
  type: SAVE_CLUSTER_CANCEL,
  payload
})

export const saveClusterFetchEpic = action$ => action$
  .ofType(SAVE_CLUSTER)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v1/workspace/createworkspace?${action.payload.params}`, 'POST', true, action.payload.data))
    .map(response => saveClusterFetchSuccess(response))
    .takeUntil(action$.ofType(SAVE_CLUSTER_CANCEL))
    .catch(error => Observable.of({
      type: SAVE_CLUSTER_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })))

export function saveClusterFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case SAVE_CLUSTER: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        flag: false
      }
    }
    case SAVE_CLUSTER_SUCCESS: {
      return {
        ...state,
        data: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case SAVE_CLUSTER_FAILURE: {
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        flag: false
      }
    }
    default:
      return state
  }
}
