import React from 'react';
// import { FormattedMessage } from 'react-intl';


const BrowserSupportPage = () => (
  <a className='link-for-chrome' rel="noopener noreferrer" href="https://www.google.com/chrome/" target="_blank">
    <div className='browser-support'>
      <div className='browser-support-container'>
        {/* <FormattedMessage id="Your browser is not supported, please upgrade!!!" defaultMessage="Your browser is not supported, please upgrade!!!" /> */}
        <h1>Sorry we are still trying to support this browser!</h1>
        <div className='browser-support-container-help-text'>
          Unfortunately still this browser is not yet supported on our platform. For now we recommend using Chrome for the best experience!
          <p className='browser-support-container-subhelp'>Please contact your internal IT team to download the current version of Google Chrome.</p>
        </div>
        <div className='chrome-logo' />

        <div className='browser-support-container-footer-text'>
          Experience Ontosight®, the combination of our self-learning life science ontology and relevant insights. Access the world’s largest research graph at your fingertips - covering insights from the first stages of drug discovery to commercialization.
        </div>
      </div>
    </div>
  </a>
)


export default BrowserSupportPage;
