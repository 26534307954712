import { Observable } from 'rxjs/Observable';
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { apiCall } from '../../../common/utils';
import { ERROR } from '../../../common/container/Status/logic';


const INITIAL_STATE = {
  authorsId: [],
  loading: false,
  error: false,
  flag: false
};

export const KOLAUTHORES_FETCH = 'KOLAUTHORES_FETCH';
const KOLAUTHORES_FETCH_SUCCESS = 'KOLAUTHORES_FETCH_SUCCESS';
export const KOLAUTHORES_FETCH_FAILURE = 'KOLAUTHORES_FETCH_FAILURE';


export const kolAuthorsFetchAction = payload => ({
  type: KOLAUTHORES_FETCH,
  payload
});

const kolAuthorsFetchSuccess = payload => ({
  type: KOLAUTHORES_FETCH_SUCCESS,
  payload
});

export const kolAuthorsFetchEpic = action$ => action$
  .ofType(KOLAUTHORES_FETCH)
  .mergeMap(action => staticAjax(apiCall(`${process.env.faceliftApiUrl}v0/search/kolresult?query=${action.payload.query}&countries=${action.payload.countries}&Kol_dataset=${action.payload.Kol_dataset}&kol_type=${action.payload.kolType.kolType}${action.payload.kolType.kolType === 'Emerging KOLs' ? `&kol_assetClass=${action.payload.kolType.assetType}` : ''}`, 'POST', true, action.payload.body))
    .map(response => kolAuthorsFetchSuccess(response))
    .catch(error => Observable.of({
      type: KOLAUTHORES_FETCH_FAILURE,
      payload: error
    }, {
      type: ERROR,
      payload: error
    })));

export function kolAuthorsIdsFetchReducer (state = INITIAL_STATE, action = null) {
  switch (action.type) {
    case KOLAUTHORES_FETCH: {
      return {
        ...state,
        authorsId: [],
        loading: true,
        error: false,
        flag: false
      }
    }
    case KOLAUTHORES_FETCH_SUCCESS: {
      return {
        ...state,
        authorsId: action.payload.response.data,
        loading: false,
        error: false,
        flag: true
      }
    }
    case KOLAUTHORES_FETCH_FAILURE: {
      return {
        ...state,
        authorsId: [],
        loading: false,
        error: true,
        flag: false,
      }
    }
    default:
      return state
  }
}

// Score Equalizer Filters

const SET_KOL_FILTERS = 'SET_KOL_FILTERS';
const RESET_KOL_FILTERS = 'RESET_KOL_FILTERS';
const SET_KOL_FILTERS_INITIAL_STATE = 'SET_KOL_FILTERS_INITIAL_STATE';

const initialState = {
  thought_leaders: [
    {
      name: 'Publications',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Clinical Trials',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Congresses',
      value: 10,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Guidelines',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Societies',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Hospitals',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Regulatory Bodies',
      value: 35,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'HTA',
      value: 35,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Advocacy',
      value: 20,
      showDate: false,
      startDate: 0,
      endDate: 0
    }
  ],
  commercial: [
    {
      name: 'Publications',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Clinical Trials',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Congresses',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Guidelines',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Societies',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Hospitals',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Regulatory Bodies',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'HTA',
      value: 50,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Advocacy',
      value: 50,
      startDate: 0,
      endDate: 0
    }
  ],
  scientific: [
    {
      name: 'Publications',
      value: 60,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Clinical Trials',
      value: 20,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Congresses',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Guidelines',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Societies',
      value: 10,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Hospitals',
      value: 10,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Regulatory Bodies',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'HTA',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Advocacy',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    }
  ],
  speakers: [
    {
      name: 'Publications',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Clinical Trials',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Congresses',
      value: 70,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Guidelines',
      value: 0,
      showDate: true,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Societies',
      value: 15,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Hospitals',
      value: 15,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Regulatory Bodies',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'HTA',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    },
    {
      name: 'Advocacy',
      value: 0,
      showDate: false,
      startDate: 0,
      endDate: 0
    }
  ]
};

const KOL_FILTERS_INITIAL_STATE = initialState.thought_leaders;

export const setKOLSFiltersAction = payload => ({
  type: SET_KOL_FILTERS,
  payload
});

export const resetKOLSFiltersAction = () => ({
  type: RESET_KOL_FILTERS
});

export const setKOLSFiltersInitialState = (userType) => {
  const payload = initialState[userType];
  return {
    type: SET_KOL_FILTERS_INITIAL_STATE,
    payload
  };
};

export function kolsFiltersReducer (state = KOL_FILTERS_INITIAL_STATE, action = null) {
  switch (action.type) {
    case SET_KOL_FILTERS: {
      return action.payload;
    }
    case RESET_KOL_FILTERS: {
      return KOL_FILTERS_INITIAL_STATE
    }
    case SET_KOL_FILTERS_INITIAL_STATE: {
      return action.payload;
    }
    default:
      return state
  }
}

// Countries filters

const SET_SELECTED_COUNTRIES_FILTERS = 'SET_SELECTED_COUNTRIES_FILTERS';
const RESET_COUNTRIES_FILTERS = 'RESET_COUNTRIES_FILTERS';
const COUNTRIES_FILTERS_INITIAL_STATE = sessionStorage.getItem('countries') ? JSON.parse(sessionStorage.getItem('countries')).length > 0 ? JSON.parse(sessionStorage.getItem('countries')) : [] : [];// ['Argentina', 'Australia', 'Austria', 'Bahamas', 'Belgium', 'Brazil', 'Canada', 'Chile', 'China', 'Colombia', 'Denmark', 'Dominican Republic', 'Finland', 'France', 'Georgia', 'Germany', 'Greece', 'Hong Kong', 'India', 'Iran', 'Ireland', 'Israel', 'Italy', 'Japan', 'Korea South', 'Mexico', 'Netherlands', 'Norway', 'Oman', 'Peru', 'Philippines', 'Poland', 'Russia', 'Singapore', 'South Korea', 'Spain', 'Sweden', 'Switzerland', 'Taiwan', 'Tunisia', 'Turkey', 'United Arab Emirates', 'United Kingdom', 'United States Of America', 'Uruguay'];

export const setSelectedCountriesFiltersAction = payload => ({
  type: SET_SELECTED_COUNTRIES_FILTERS,
  payload
});

export const resetCountriesFiltersAction = () => ({
  type: RESET_COUNTRIES_FILTERS
});

export function kolsCountriesFiltersReducer(state = COUNTRIES_FILTERS_INITIAL_STATE, action = null) {
  switch (action.type) {
    case SET_SELECTED_COUNTRIES_FILTERS: {
      return action.payload;
    }
    case RESET_COUNTRIES_FILTERS: {
      return [];
    }
    default:
      return state
  }
}

// kolType Filters

const SET_KOL_TYPE_FILTER = 'SET_KOL_TYPE_FILTER';
const KOL_TYPE_FILTER_INITIAL_STATE = {
  kolType: 'Top KOLs',
  assetType: ''
};

export const setKOLTypeFilter = payload => ({
  type: SET_KOL_TYPE_FILTER,
  payload
});

export function kolTypeFilterReducer(state = KOL_TYPE_FILTER_INITIAL_STATE, action = null) {
  switch (action.type) {
    case SET_KOL_TYPE_FILTER: {
      return action.payload
    }
    default:
      return state
  }
}

