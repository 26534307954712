import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LegendItem from './legendItem'
import COLOR from '../../container/palette.json'

class Legends extends Component {
  renderLegends () {
    const notAllowed = this.props.activeList.length === 1;
    if (Array.isArray(this.props.legends)) {
      return this.props.legends.map((item, i) => (
        <LegendItem
          key={i}
          option={i}
          color={this.props.color[i]}
          category={item}
          borderRadius={10}
          className={this.props.itemClass}
          clickable={this.props.activeList.includes(item)}
          clicked={this.props.clicked}
          allowClick={this.props.clickable}
          notAllowed={notAllowed}
        />
      ))
    }
    return Object.keys(this.props.legends).map((d, i) => (
      <LegendItem
        key={i}
        option={i}
        color={this.props.legends[d]}
        category={d}
        borderRadius={10}
        className={this.props.itemClass}
        clickable={this.props.activeList.includes(d)}
        clicked={this.props.clicked}
        allowClick={this.props.clickable}
        notAllowed={notAllowed}
      />
    ))
  }
  render () {
    return (
      <div className={`legends ${this.props.className}`}>
        { this.renderLegends() }
      </div>
    )
  }
}

Legends.propTypes = {
  legends: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  color: PropTypes.array,
  itemClass: PropTypes.string,
  clicked: PropTypes.func,
  activeList: PropTypes.array,
  clickable: PropTypes.bool,
}

Legends.defaultProps = {
  className: '',
  color: COLOR,
  itemClass: '',
  clicked: () => null,
  activeList: [],
  clickable: false
}

export default Legends
