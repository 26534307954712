import React, { Component } from 'react'
import { parse, stringify } from 'query-string'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loginAction } from '../Login/logic'
import { getCookie } from '../../utils';

const LoadingView = () => (
  <div className='big-loader loader' style={{ height: '100vh', top: 0 }}>
    <div className='spinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
      <div className="loading-text">Loading Dashboards...</div>

    </div>
  </div>
)
const AlreadyLoggedIn = (app = 'facelift') => (BaseComponent) => {
  class AlreadyLoggedInCheck extends Component {
    constructor (props) {
      super(props)
      const query = parse(window.location.hash).alreadyLoggedIn
      const hash = parse(window.location.search).alreadyLoggedIn
      let type = 'component'
      if ((query === 'true' || hash === 'true') && !getCookie('accessToken')) {
        type = 'loading'
      } else if (!getCookie('accessToken')) {
        window.location.replace(`${process.env.mainAppUrl || 'https://ontosight.ai'}`)
      }
      this.state = {
        type,
        noAccess: false,
        openModal: false
      }
      this.intializeSSO()
    }
    componentDidMount () {
      this.ssoLogin()
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.auth.loggedIn !== this.props.auth.loggedIn && nextProps.auth.loggedIn) {
        this.setState({
          type: 'component'
        })
      }
    }
    intializeSSO = () => {
      if (sso && this.state.type === 'loading') {
        sso.config({
          base_url: process.env.sso.baseUrl,
          client_id: process.env.sso.clientId,
          window_type: 'iframe',
          response_type: 'code',
          divId: 'ssoLogin',
          lang: this.props.lang,
          silent: true
        })
      }
    }
    replaceUrl = () => {
      const { alreadyLoggedIn, ...others } = parse(window.location.search)
      this.props.history.replace(`${stringify({ ...others })}`)
    }
    ssoLogin = () => {
      if (sso && this.state.type === 'loading') {
        sso.login({
          scope: process.env.sso.scope, nosession: true, silent: true, lang: this.props.lang
        }, (status) => {
          if (status.status === 2) {
            window.location.replace(`${process.env.mainAppUrl || 'https://ontosight.ai'}/dashboards`)
          } else if (status.status === 1) {
            window.location.replace(`${process.env.mainAppUrl || 'https://ontosight.ai'}`)
          } else {
            this.props.loginAction({
              params: stringify({
                code: status.code,
                app
              })
            })
          }
        })
      }
    }
    render () {
      if (this.state.type === 'loading') {
        const style = {
          position: 'fixed',
          top: -10000,
          left: -10000
        }
        return (
          <div>
            <div id='ssoLogin' style={style} />
            <LoadingView />
          </div>
        )
      }
      return <BaseComponent openModal={this.state.openModal} noAccess={this.state.noAccess} {...this.props} />
    }
  }
  AlreadyLoggedInCheck.propTypes = {
    lang: PropTypes.string,
    loginAction: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  }
  AlreadyLoggedInCheck.defaultProps = {
    lang: 'en'
  }
  return withRouter(connect(state => ({
    auth: state.auth
  }), { loginAction })(AlreadyLoggedInCheck))
}
export default AlreadyLoggedIn
